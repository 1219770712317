import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ellipseAddress } from "src/helpers/utilities";

import { discordUrl, twitterUrl } from "../App";

import "./Header.scss";

const OVERLAY_BREAKPOINT = 920;

interface IHeaderProps {
  connectWallet: () => void;
  killSession: () => void;
  connected?: boolean;
  address: string;
  chainId?: number;
  lightness: number;
  opaque?: boolean;
}

export default function Header(props: IHeaderProps) {
  const { connectWallet, killSession, connected, address, lightness, opaque } =
    props;

  const selectorRef = useRef<HTMLDivElement>(null);
  const [selectorActive, setSelectorActive] = useState(false);
  const [overlayActive, setOverlayActive] = useState(false);

  const navigate = useNavigate();

  function toggleStorySelector() {
    setSelectorActive(!selectorActive);
  }

  function toggleOverlay() {
    setOverlayActive(!overlayActive);
  }

  useEffect(() => {
    function onClick(event: Event) {
      const selectorEl = selectorRef.current;

      if (
        selectorEl &&
        selectorActive &&
        !selectorEl.contains(event.target as Node)
      ) {
        setSelectorActive(false);
      }
    }

    function onResize() {
      if (document.documentElement.clientWidth > OVERLAY_BREAKPOINT) {
        setOverlayActive(false);
      }
    }

    window.addEventListener("click", onClick);
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("click", onClick);
      window.removeEventListener("resize", onResize);
    };
  });

  return (
    <header
      style={{
        color: `hsl(20, 0%, ${100 * (overlayActive ? 0 : lightness)}%)`,
      }}
      className={`${opaque ? "opaque" : ""} ${
        !overlayActive ? (lightness >= 0.5 ? "light" : "") : ""
      } ${overlayActive ? "active" : ""}`}>
      <div className="header-container">
        {connected ? (
          <div className="wallet-address">
            Wallet connected: {ellipseAddress(address)}
          </div>
        ) : (
          <div className="wallet-placeholder" />
        )}
        <div className="header-links">
          <div className="header-main">
            <Link to="/">
              <img
                className="header-logo"
                src="/fyat_lux_logo.svg"
                alt="Fyat Lux"
              />
            </Link>
            <div className="header-hamb" onClick={toggleOverlay}>
              {overlayActive ? (
                <NavX lightness={overlayActive ? 0 : lightness} />
              ) : (
                <NavHamb lightness={overlayActive ? 0 : lightness} />
              )}
            </div>
          </div>
          <nav>
            <div className="nav-links">
              <span
                className="nav-link nav-story"
                onClick={toggleStorySelector}
                ref={selectorRef}>
                <span className="nav-story-btn">
                  Story <NavArrow lightness={overlayActive ? 0 : lightness} />
                </span>
                {selectorActive && (
                  <div className="nav-story-popup">
                    <Link
                      className="nav-story-link"
                      to="/vault-of-the-ancients"
                      onClick={toggleOverlay}>
                      <div className="nav-story-image -vault" />
                      <span className="nav-story-label">
                        Vault of the Ancients
                      </span>
                    </Link>
                    <Link
                      className="nav-story-link"
                      to="/glimpses"
                      onClick={toggleOverlay}>
                      <div className="nav-story-image -glimpses" />
                      <span className="nav-story-label">Glimpses</span>
                    </Link>
                  </div>
                )}
              </span>
              <a className="nav-link" href={discordUrl} onClick={toggleOverlay}>
                <i className="fa-brands fa-discord" />
              </a>
              <a className="nav-link" href={twitterUrl} onClick={toggleOverlay}>
                <i className="fa-brands fa-twitter" />
              </a>
              {connected && (
                <Link
                  className="nav-link"
                  to="/my-collection"
                  onClick={toggleOverlay}>
                  My Collection
                </Link>
              )}
            </div>

            {!connected && (
              <div className="nav-connect-link">
                <button
                  onClick={() => {
                    toggleOverlay();
                    connectWallet();
                    // navigate("/my-collection");
                  }}
                  style={{
                    borderColor: `hsl(0, 0%, ${
                      100 * (overlayActive ? 0 : lightness)
                    }%)`,
                  }}>
                  Connect Wallet
                </button>
              </div>
            )}
            {connected && (
              <div className="nav-connect-link">
                <div>
                  <button
                    onClick={() => {
                      toggleOverlay();
                      killSession();
                      navigate("/");
                    }}
                    style={{
                      borderColor: `hsl(0, 0%, ${
                        100 * (overlayActive ? 0 : lightness)
                      }%)`,
                    }}>
                    Disconnect
                  </button>
                </div>
              </div>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
}

function NavArrow(props: { lightness: number }) {
  return (
    <svg
      className="show"
      width="25"
      height="8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.964.917 5.131 4.75 1.297.917.131 2.083l5 5 5-5L8.964.917Z"
        fill={`hsl(0, 0%, ${100 * props.lightness}%)`}
      />
    </svg>
  );
}

function NavHamb(props: { lightness: number }) {
  return (
    <svg
      width="24"
      height="20.057"
      viewBox="0 0 6.35 5.307"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 .272h6.35M0 2.653h6.35M0 5.035h6.35"
        fill="none"
        stroke={`hsl(0, 0%, ${100 * props.lightness}%)`}
        strokeWidth=".544"
      />
    </svg>
  );
}

function NavX(props: { lightness: number }) {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 1.8L16.2 0 9 7.2 1.8 0 0 1.8 7.2 9 0 16.2 1.8 18 9 10.8l7.2 7.2 1.8-1.8L10.8 9 18 1.8z"
        fill={`hsl(0, 0%, ${100 * props.lightness}%)`}
      />
    </svg>
  );
}
