import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const SContainer = styled.div`
  width: 100vw;
  min-width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
  align-self: center;
`;

const STermsContainer = styled.div`
  height: 100%;
  min-height: 100%;
  //width: 60%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  background-color: #f2eeec;
  justify-content: flex-start;
  padding: 100px 48px 32px;
`;

const SHeaderText = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
`;

const SMainText = styled.h4`
  max-width: 70vw;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  align-self: center;
  text-align: left;
`;

const TermsOfService = (props: {
  logAnalytics: (eventName: string) => void;
}) => {
  const location = useLocation();

  useEffect(() => {
    console.log("tos page");
    props.logAnalytics("tos page");
  }, [location]);

  return (
    <SContainer>
      <STermsContainer>
        <SHeaderText>Fyat Lux Platform Terms of Service</SHeaderText>
        <SMainText>
          <b>Last updated: 28 December 2023</b>
          <br />
          <br />
          Combining groundbreaking technologies with mind-blowing art, 10KAF LLC
          (“Fyat Lux”, “Company” or “we”) facilitates and enables users to
          engage in the next evolution of NFTs for world-building and
          storytelling. Before delving into the Fyat Lux universe, please read
          these Terms of Service (“Terms”) carefully.
          <br />
          <br />
          These Terms govern any access or use of the website at
          https://fyatlux.com/, and any other web site, mobile application,
          marketplace or application program interface operated or made
          available by Fyat Lux (the “Platform”) as well as any services
          provided on or through the Platform (“Services”). By using our
          Services, you agree to these Terms, including Section 20 which
          contains a class action waiver and a mandatory arbitration provision
          that waives your right to sue in court or have a jury trial. If you do
          not agree to these Terms, do not use our Services.
          <br />
          <br />
          We may make changes to these Terms from time to time. If we make
          changes, we will provide you with notice of such changes, such as by
          sending an email, providing a notice through our Services or updating
          the date at the top of these Terms. Unless we say otherwise in our
          notice, the amended Terms will be effective immediately, and your
          continued use of our Services after we provide such notice will
          confirm your acceptance of the changes. If you do not agree to the
          amended Terms, you must stop using our Services.
          <br />
          <br />
          1. Services and Digital Collectibles
          <br />
          <br />
          The Fyat Lux Platform allows users of the Platform to purchase NFTs
          (as defined below) using cryptocurrency which may be used to access
          and download a digital 3D VXR/VXA/VXM/VOX/GLTF/GLB/OBJ model of a
          character (the “3D Model”) and a 2D PNG/GIF/JPEG image of the
          character (“2D Image”) and to purchase physical merchandise
          (“Merchandise”), for example, a physical card embodying a rendering of
          an owner’s Digital Collectible (the “Card”). Services as used herein
          includes any services, features and functionality of the Platform
          related to the purchase or sale of any blockchain-based, cryptographic
          nonfungible token (“NFT”) which, together with the license rights to
          any associated digital works of authorship or other content, whether
          or not copyrighted or copyrightable, and regardless of the format in
          which any of the foregoing is made available (“Related Content”),
          comprises and is referred to herein as a “Digital Collectible”. The
          terms of your license to the Related Content and other terms
          applicable to the Digital Collectible will be presented to you at the
          time of purchase.
          <br />
          <br />
          Additionally, certain Services may be made available exclusively to
          the owner of a particular Digital Collectible. You may be required to
          demonstrate ownership of the applicable Digital Collectible in order
          to access these Services, which Services include, but are not limited
          to: (i) the ability to view the NFT in a 3D environment or in
          augmented reality; (ii) the ability to create a 2D Image; or (iii) the
          ability to purchase a Card. Without limiting any other right to
          terminate or suspend Services under these Terms, we may terminate your
          access or use of these Services in the event that you transfer the
          applicable Digital Collectible to someone else.
          <br />
          <br />
          Any digital works of authorship or other content made available
          through the Platform to an owner of a Digital Collectible that is
          intended as “Additional Features” (as that term is defined in the{" "}
          <Link className="legal-link" to="/digital-collectible-terms">
            Digital Collectible Terms
          </Link>
          ) will be identified as such on the Platform or at the time of
          download. Any such content will be licensed to you for as long as you
          own the applicable Digital Collectible pursuant to the terms of any
          license presented at the time of download or, if no such terms are
          presented, pursuant to the applicable Digital Collectible Terms as
          Related Content for that particular Digital Collectible. Any other
          digital works of authorship and other content made available through
          the Platform is part of the Services and no license rights are granted
          to you with respect to any such content.
          <br />
          <br />
          None of Fyat Lux or any third party has any obligation to provide any
          Additional Benefit and none of Fyat Lux or any third party will have
          any responsibility or liability for, arising out of, or relating to
          any Additional Benefit.
          <br />
          <br />
          2. Privacy
          <br />
          <br />
          For information about how we collect, use, share or otherwise process
          information about you, please see our Privacy Policy available{" "}
          <Link className="legal-link" to="/privacy">
            <a>Here</a>
          </Link>
          <br />
          <br />
          3. Eligibility
          <br />
          <br />
          In order to use our Services, you must meet the following eligibility
          criteria:
          <br />● You must not be located in a country that is subject to the
          United States government embargo, or that has been designated by the
          United States government as a "terrorist supporting" country.
          <br />● You must not be listed on any United States government list of
          prohibited or restricted parties.
          <br />● You must be at least 13 years of age. If you are under 18
          years of age (or the age of legal majority where you live), you may
          use our Services only under the supervision of a parent or legal
          guardian who agrees to be bound by these Terms. If you are a parent or
          legal guardian of a user under the age of 18 (or the age of legal
          majority), you agree to be fully responsible for the acts or omissions
          of such user in relation to our Services. If you use our Services on
          behalf of another person or entity, (a) all references to "you"
          throughout these Terms will include that person or entity, (b) you
          represent that you are authorized to accept these Terms on that
          person’s or entity’s behalf, and (c) in the event you or the person or
          entity violates these Terms, both you and the person or entity agree
          to be responsible to us.
          <br />
          <br />
          4. Your User Account and Account Security
          <br />
          <br />
          At a future date, we may require you to register for an account to
          access our Services. When you register for an account, you must
          provide accurate account information and promptly update this
          information if it changes. You also must maintain the security of your
          account and promptly notify us if you discover or suspect that someone
          has accessed your account without your permission. You must not permit
          others to use your account credentials. You are responsible for the
          activities of any users that occur in connection with your account. We
          reserve the right to reclaim usernames, including on behalf of
          businesses or individuals that hold legal claim, including trademark
          rights, in those usernames.
          <br />
          <br />
          Access to your account is limited solely to you. You will not sell,
          rent, lease, or grant access to your account to any person without our
          prior written permission.
          <br />
          <br />
          You are solely responsible for maintaining the security of your
          account and control over any usernames, passwords, or any other codes
          that you use to access our Services. You will not hold us responsible
          for managing and maintaining the security of your account. We are not
          responsible (and you will not hold us responsible) for any
          unauthorized access to or use of your account. You are responsible for
          monitoring your account. If you notice any unauthorized or suspicious
          activity in your account, please notify us immediately.
          <br />
          <br />
          5. Purchases of Digital Collectibles and Merchandise
          <br />
          <br />
          5.1 Digital Collectibles. We do not offer a hosted digital wallet on
          the Platform or otherwise custody Digital Collectibles for our users.
          You will be required to link a supported blockchain-based digital
          wallet (“Supported Wallet”) provided by supported third-party unhosted
          wallet providers (“Supported Wallet Provider”) to the Platform before
          you will be able to purchase a Digital Collectible. The list of
          Supported Wallets and Supported Wallet Providers, which we may update
          at our discretion, is as follows: Metamask, Torus, WalletConnect, and
          Fortmatic. We will deliver any Digital Collectibles that you purchase
          from us directly to your Supported Wallet and you will be responsible
          for safekeeping the passwords and keys associated with your Supported
          Wallet. We will not be able to recover purchased Digital Collectibles
          for you in the event that you lose access to your Supported Wallet
          account or under any other circumstances.
          <br />
          <br />
          Each Supported Wallet Provider is a third-party company that offers
          unhosted digital wallet products and services. We are not in any way
          affiliated or partnered with Supported Wallet Providers. Your use of
          each Supported Wallet Provider’s products and services is subject to
          its applicable terms of service. We will not be liable to you for any
          loss that results from your use of a Supported Wallet Provider’s
          products and services, including Supported Wallets.
          <br />
          <br />
          Currently, the Fyat Lux NFTs are stored on the Ethereum blockchain,
          and we currently only accept payments for Digital Collectibles in
          Ether. However, we may amend the list of supported cryptocurrencies
          from time to time. You cannot maintain a balance of cryptocurrency
          with us for purposes of making purchases through our Services. You
          must link your Supported Wallet to the Services and use a supported
          type of cryptocurrency from your Supported Wallet to complete your
          purchase.
          <br />
          <br />
          All pricing and payment terms are as set forth on the Platform, and
          any payment obligations you incur are binding at the time of purchase.
          All sales are final. Unless otherwise required by applicable law, we
          will not provide a refund on any purchase.
          <br />
          <br />
          You represent and warrant that any purchase of a Digital Collectible
          by you is solely for your personal collection, use and enjoyment, and
          not for speculative or investment purposes, for use as a substitute
          for currency or other medium of exchange, or for resale or
          redistribution. You acknowledge and understand that by purchasing a
          Digital Collectible you are not acquiring any equity or other
          ownership or profit-sharing interest in Fyat Lux, or any of its
          affiliates or any brand or other business venture.
          <br />
          <br />
          You will not portray any Digital Collectible as an opportunity to gain
          economic benefit or profit, or as an investment, equity, or other
          ownership or profit-sharing interest in Fyat Lux, or any of its
          affiliates or any brand or other business venture. You will comply
          with any marketing policies or guidelines published by Fyat Lux for
          NFTs or Digital Collectibles.
          <br />
          <br />
          LEGISLATIVE OR REGULATORY CHANGES AT THE STATE, FEDERAL OR
          INTERNATIONAL LEVEL MAY ADVERSELY AFFECT THE USE, TRANSFER, OR
          EXCHANGE OF THE DIGITAL COLLECTIBLES.
          <br />
          <br />
          5.2 Merchandise. From time to time, Fyat Lux may make Merchandise
          (including Cards) available for purchase. At the time of purchase, you
          may be asked to provide certain information required for order
          processing including but not limited to providing your full name and a
          valid shipping address. Each third party engaged by Fyat Lux to
          satisfy orders of Merchandise, including Cards (as applicable), is a
          third-party company. We are not in any way affiliated or partnered
          with such providers. Your use of such provider’s products and services
          is subject to its applicable terms of service. We will not be liable
          to you for any loss that results from your use of such provider’s
          products and services. All purchases of Merchandise must be made in
          U.S. Dollars or in another internationally-recognized currency (not in
          cryptocurrency). All pricing and payment terms are as set forth on the
          Platform, and any payment obligations you incur are binding at the
          time of purchase. All sales are final. Unless otherwise required by
          applicable law, we will not provide a refund on any purchase.
          <br />
          <br />
          6. Digital Collectibles Acquired through Third Party Platforms
          <br />
          <br />
          With respect to any Digital Collectible originally sold through the
          Platform but that you acquired from a third party through a third
          party platform or service, by agreeing to these Terms, you agree to be
          bound by the applicable Digital Collectible Terms for the Digital
          Collectible. In addition, you acknowledge and agree that in the event
          the third party from whom you purchased the Digital Collectible does
          not pay any amounts owed to Fyat Lux in connection with the transfer
          to you, Fyat Lux may, at its option and discretion (and without
          limiting its right or ability to do so as otherwise provided in these
          Terms) refuse to provide you with access to the Platform or any
          Service, unless and until all outstanding amounts have been paid.
          <br />
          <br />
          7. Suspension of Account
          <br />
          <br />
          In the event that you are required to create an account with us, we
          have the right to immediately suspend your account, pause or cancel
          your access to our Services, or close your account if we suspect, in
          our sole discretion, that (a) your account is being used for money
          laundering, to evade sanctions or to engage in illegal activity, (b)
          you have concealed or provided false identification information or
          other details, (c) you have engaged in fraudulent activity, or (d) you
          have engaged in transactions in violation of these Terms.
          <br />
          <br />
          8. License to Services and Ownership
          <br />
          <br />
          Our Services, including the text, graphics, images, photographs,
          videos, illustrations and other content contained therein, are owned
          by Fyat Lux or our licensors and are protected under both United
          States and foreign laws. Except as explicitly stated in these Terms,
          all rights in and to our Services are reserved by us or our licensors.
          Subject to your compliance with these Terms, you are hereby granted a
          limited, nonexclusive, nontransferable, non-sublicensable, revocable
          license to access and use our Services for your own personal,
          noncommercial use. Any use of our Services other than as specifically
          authorized herein, without our prior written permission, is strictly
          prohibited, will terminate the license granted herein and violate our
          intellectual property rights.
          <br />
          <br />
          9. Related Content
          <br />
          <br />
          9.1 Generally. Each Digital Collectible is a limited-edition digital
          asset comprised of an NFT and associated Related Content. Related
          Content is licensed to you, not acquired by you when you acquire the
          NFT. Ownership of any Related Content is retained by Fyat Lux or the
          third party that developed or acquired ownership of the Related
          Content (any such third party, a “Third Party Developer”). No
          ownership or title in or to any Related Content is transferred to you,
          and no other right or interest in any Related Content is transferred
          to you except for the limited license rights that are expressly set
          forth in the Digital Collectible Terms. For clarification, the Related
          Content is neither stored nor embedded in the Digital Collectible, but
          is accessible through the Digital Collectible, subject to compliance
          with these Terms and the Digital Collectible Terms.
          <br />
          <br />
          9.2 Restrictions. You will not, attempt to, or permit or enable any
          third party to: (a) separate the Related Content from the Digital
          Collectible; (b) modify the Related Content, unless expressly
          permitted to do so pursuant to the Digital Collectible Terms; (c)
          register or attempt to register any trademark or copyright or
          otherwise acquire additional intellectual property rights in or to any
          Related Content; (d) use any Related Content to create, endorse,
          support, promote or condone any content, material or speech that is
          defamatory, obscene, pornographic, indecent, abusive, offensive,
          harassing, violent, hateful, racist, discriminatory, inflammatory or
          otherwise objectionable or inappropriate as determined by Fyat Lux at
          its discretion; (e) commercialize the Related Content or use the
          Related Content in connection with any business, message, product, or
          service, or in any manner that may imply endorsement of any business,
          message, product, or service; (f) use the Related Content in any
          manner that is likely to cause confusion or dilute, blur, or tarnish
          the Related Content or any intellectual property rights in the Related
          Content; or (g) use the Related Content in any manner that infringes,
          violates or misappropriates any third party intellectual property or
          intellectual property right, or that violates the these Terms or the
          Digital Collectible Terms.
          <br />
          <br />
          9.3 Transfer or other Fees. Fyat Lux and Third Party Developers, as
          well as our affiliates, licensors and designee(s), may be entitled to
          receive transfer or other fees or royalties from your purchase or
          subsequent sales of a Digital Collectible, which defray costs
          associated with licensing the Related Content, administration of the
          Digital Collectible ownership and corresponding license rights, and
          making any optional Additional Benefits (as defined below) available
          to you or subsequent holders of the Digital Collectible.
          <br />
          <br />
          10. Prohibited Conduct and Content
          <br />
          <br />
          You will not violate any applicable law, contract, intellectual
          property right or other third-party right or commit a tort, and you
          are solely responsible for your conduct while using our Services. You
          will not:
          <br />▪ use or attempt to use another user’s account without
          authorization from that user and Fyat Lux;▪ impersonate or otherwise
          misrepresent your affiliation with a person or entity;
          <br />▪ sell, resell or commercially use our Services;
          <br />▪ copy, reproduce, distribute, publicly perform or publicly
          display all or portions of our Services, except as expressly permitted
          by us or our licensors;
          <br />▪ modify our Services, remove any proprietary rights notices or
          markings, or otherwise make any derivative works based upon our
          Services;
          <br />▪ use our Services other than for their intended purpose and in
          any manner that could interfere with, disrupt, negatively affect or
          inhibit other users from fully enjoying our Services or that could
          damage, disable, overburden or impair the functioning of our Services
          in any manner;
          <br />▪ reverse engineer any aspect of our Services or do anything
          that might discover source code or bypass or circumvent measures
          employed to prevent or limit access to any part of our Services;
          <br />▪ attempt to circumvent any content-filtering techniques we
          employ or attempt to access any feature or area of our Services that
          you are not authorized to access;
          <br />▪ use any data mining, robots or similar data gathering or
          extraction methods designed to scrape or extract data from our
          Services;
          <br />▪ develop or use any applications that interact with our
          Services without our prior written consent; or
          <br />▪ use our Services for any illegal or unauthorized purpose, or
          engage in, encourage or promote any activity that violates these
          Terms.
          <br />
          <br />
          Enforcement of this Section 10 is solely at Fyat Lux’s discretion, and
          failure to enforce this section in some instances does not constitute
          a waiver of our right to enforce it in other instances. In addition,
          this Section 10 does not create any private right of action on the
          part of any third party or any reasonable expectation that our
          Services will not contain any content that is prohibited by such
          rules.
          <br />
          <br />
          11. Trademarks
          <br />
          <br />
          Fyat Lux and our logos, our product or service names, our slogans and
          the look and feel of our Services are trademarks of Fyat Lux and may
          not be copied, imitated or used, in whole or in part, without our
          prior written permission. All other trademarks, registered trademarks,
          product names and company names or logos mentioned on our Services are
          the property of their respective owners. Reference to any products,
          services, processes or other information by trade name, trademark,
          manufacturer, supplier or otherwise does not constitute or imply
          endorsement, sponsorship or recommendation by us.
          <br />
          <br />
          12. Feedback
          <br />
          <br />
          You may voluntarily post, submit or otherwise communicate to us any
          questions, comments, suggestions, ideas, original or creative
          materials or other information about Fyat Lux or our Services
          (collectively, "Feedback"). We may use such Feedback for any purpose,
          commercial or otherwise, without acknowledgment or compensation to
          you, including, without limitation, to develop, copy, publish, or
          improve the Feedback in Fyat Lux’s sole discretion. You understand
          that Fyat Lux may treat Feedback as nonconfidential.
          <br />
          <br />
          13. Third Party Content
          <br />
          <br />
          We may provide information about Additional Benefits made available by
          third parties or other third-party products, services, activities or
          events, or we may allow third parties to make their content and
          information available on or through our Services (collectively,
          "Third-Party Content"). We provide Third-Party Content as a service to
          those interested in such content. Your dealings or correspondence with
          third parties and your use of or interaction with any Third-Party
          Content are solely between you and the third party. Fyat Lux does not
          control or endorse, and makes no representations or warranties
          regarding, any Third-Party Content, and your access to and use of such
          Third-Party Content is at your own risk.
          <br />
          <br />
          14. Indemnification
          <br />
          <br />
          To the fullest extent permitted by applicable law, you will indemnify,
          defend and hold harmless Fyat Lux and our subsidiaries and affiliates,
          and each of our respective officers, directors, agents, partners and
          employees (individually and collectively, the " Fyat Lux Parties")
          from and against any losses, liabilities, claims, demands, damages,
          expenses or costs ("Claims") arising out of or related to (a) your
          access to or use of our Services (including, without limitation,
          Digital Collectibles, Additional Benefits and Related Content); (b)
          your Feedback; (c) your violation of these Terms (including, without
          limitation, the terms and conditions with respect to any Digital
          Collectible, Additional Benefit or Related Content) or the Digital
          Collectible Terms; (d) your violation, misappropriation or
          infringement of any intellectual property, privacy, or other rights of
          another; or (e) your conduct in connection with our Services. You will
          cooperate with the Fyat Lux Parties in defending such Claims and pay
          all fees, costs and expenses associated with defending such Claims
          (including, but not limited to, attorneys' fees). Fyat Lux Parties
          will have control of the defense or settlement, at Fyat Lux X's sole
          option, of any third-party Claims.
          <br />
          <br />
          15. Disclaimers
          <br />
          <br />
          YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. EXCEPT AS OTHERWISE
          PROVIDED IN A WRITING BY US, THE SERVICES AND ANY CONTENT IN THE
          SERVICES, AND ANY AND ALL RELATED CONTENT, THIRD-PARTY CONTENT,
          BENEFIT AND DIGITAL COLLECTIBLE IS PROVIDED "AS IS" AND "AS AVAILABLE"
          WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
          BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
          A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. IN ADDITION, FYAT
          LUX DOES NOT REPRESENT OR WARRANT THAT OUR SERVICES, RELATED CONTENT,
          THIRD-PARTY CONTENT, BENEFITS OR ANY DIGITAL COLLECTIBLES ARE
          ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. WHILE FYAT LUX
          ATTEMPTS TO MAKE YOUR USE OF OUR SERVICES SAFE, WE CANNOT AND DO NOT
          REPRESENT OR WARRANT THAT OUR SERVICES OR SERVERS ARE FREE OF VIRUSES
          OR OTHER HARMFUL COMPONENTS. YOU ASSUME THE ENTIRE RISK AS TO THE
          QUALITY AND PERFORMANCE OF OUR SERVICES. WE WILL NOT BE RESPONSIBLE OR
          LIABLE TO YOU FOR ANY LOSS AND TAKE NO RESPONSIBILITY FOR, AND WILL
          NOT BE LIABLE TO YOU FOR, ANY ACCESS TO OR USE OF ANY RELATED CONTENT,
          THIRD-PARTY CONTENT, BENEFIT OR DIGITAL COLLECTIBLE INCLUDING BUT NOT
          LIMITED TO ANY LOSS, DAMAGE OR CLAIM ARISING FROM: (A) USER ERROR SUCH
          AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR
          MISTYPED ADDRESSES; (B) SERVER FAILURE OR DATA LOSS; (C) CORRUPTED
          WALLET FILES; (D) UNAUTHORIZED ACCESS TO APPLICATIONS; OR (E) ANY
          UNAUTHORIZED THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE
          USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK
          AGAINST THE SITE OR APPLICABLE BLOCKCHAIN.
          <br />
          <br />
          THE DIGITAL COLLECTIBLES ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST
          ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED ON THE BLOCKCHAIN
          NETWORK. ANY TRANSFER OF CONTROL THAT MIGHT OCCUR IN ANY UNIQUE
          DIGITAL ASSET OCCURS ON THE APPLICABLE BLOCKCHAIN NETWORK.
          <br />
          <br />
          The Digital Collectibles are currently available only on the Ethereum
          Blockchain. You will not be able to transfer your Digital Collectibles
          to any digital wallet that is not compatible with the Ethereum
          Blockchain. In the event that you inadvertently transfer a Digital
          Collectible to a digital wallet that is not compatible with the
          Ethereum Blockchain, your Digital Collectible may be lost or
          destroyed.
          <br />
          <br />
          The Digital Collectibles are transferable blockchain-based
          non-fungible tokens that evidence a limited license to Related
          Content, and, in some cases, a limited license to Third-Party Content
          and/or to access Additional Benefits, that is exclusive to the owner
          and thereby collectible as a form of alienable digital property. Like
          physical collectibles, the price of non-fungible tokens may be subject
          to fluctuation based upon broader industry trends and sentiment.
          Legislative and regulatory changes or actions at the state, federal,
          or international level may adversely affect the use, transfer,
          exchange, and value of non-fungible tokens. Unlike cryptocurrencies
          and other fungible digital assets, non-fungible tokens are not used to
          make payments. Non-fungible tokens are not mutually interchangeable
          and cannot be divided into smaller parts. These design features limit
          the usefulness of non-fungible tokens as a form of payment or
          substitute for currency. Instead, non-fungible tokens are enjoyed as
          digital collectibles.
          <br />
          <br />
          There is no guaranteed future value for Digital Collectibles. Any
          future value of a Digital Collectible is based solely on consumer
          interest and demand for that Digital Collectible and not something
          that Fyat Lux or any Third Party Developer or other third party can
          control or will attempt to control or influence. We are not
          responsible for sustained losses due to vulnerability or any kind of
          failure, abnormal behavior of software (e.g., wallet, smart contract),
          the applicable blockchain or other third party service or
          infrastructure, including Supported Wallets and Supported Wallet
          Providers, or any other features of Digital Collectibles. We are not
          responsible for sustained losses due to late reports by developers or
          representatives (or no report at all) of any issues with the
          applicable blockchain supporting Digital Collectibles including forks,
          technical node issues or any other issues having fund losses as a
          result.
          <br />
          <br />
          In some cases, Fyat Lux integrates directly with third parties,
          including but not limited to, online merchant platforms, mailing list
          platforms, and social media platforms (“Third Party APIs”). Fyat Lux
          has no control over the uptime and functionality made available
          through Third Party APIs and as such certain aspects of the Platform
          could incur an outage outside of Fyat Lux’s control, and certain
          aspects of the Platform’s functionality could be impacted by changes
          of features made available through Third Party APIs.
          <br />
          <br />
          The Fyat Lux platform is subject to flaws and acknowledge that you are
          solely responsible for evaluating any code provided by the Platform.
          Fyat Lux may experience cyber-attacks, unexpected surges in activity,
          or other operational or technical difficulties that may cause
          interruptions to or delays on the Platform. You accept the risk of the
          Platform failure resulting from unanticipated or heightened technical
          difficulties, including those resulting from sophisticated attacks,
          and you agree not to hold us accountable for any related losses.
          <br />
          <br />
          16. Disclaimer of Damages
          <br />
          <br />
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, FYAT LUX AND THE
          OTHER FYAT LUX PARTIES WILL NOT BE LIABLE TO YOU UNDER ANY THEORY OF
          LIABILITY—WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT
          LIABILITY, WARRANTY, OR OTHERWISE—FOR ANY INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, PUNITIVE OR SPECIAL DAMAGES OR LOST PROFITS,
          EVEN IF FYAT LUX OR THE OTHER FYAT LUX PARTIES HAVE BEEN ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES.
          <br />
          <br />
          17. Limitation of Liability
          <br />
          <br />
          THE TOTAL LIABILITY OF FYAT LUX AND THE OTHER FYAT LUX PARTIES FOR ANY
          CLAIM ARISING OUT OF OR RELATING TO THESE TERMS OR OUR SERVICES, OR
          ANY AND ALL RELATED CONTENT, BENEFIT AND DIGITAL COLLECTIBLE,
          REGARDLESS OF THE FORM OF THE ACTION, IS LIMITED TO THE GREATER OF
          $100 OR THE AMOUNT PAID BY YOU TO USE OUR SERVICES.
          <br />
          <br />
          The limitations set forth in Sections 16 and 17 will not limit or
          exclude liability for the gross negligence, fraud or intentional
          misconduct of Fyat Lux or the other Fyat Lux Parties or for any other
          matters in which liability cannot be excluded or limited under
          applicable law. Additionally, some jurisdictions do not allow the
          exclusion or limitation of incidental or consequential damages, so the
          above limitations or exclusions may not apply to you.
          <br />
          <br />
          18. Release
          <br />
          <br />
          To the fullest extent permitted by applicable law, you release Fyat
          Lux and the other Fyat Lux Parties from responsibility, liability,
          claims, demands and/or damages (actual and consequential) of every
          kind and nature, known and unknown (including, but not limited to,
          claims of negligence), arising out of or related to (a) disputes
          between users, (b) acts or omissions of third parties, or (c) anything
          else for which you have agreed that Fyat Lux will have no
          responsibility or liability pursuant to these Terms. If you are a
          consumer who resides in California, you hereby waive your rights under
          California Civil Code § 1542, which provides: “A general release does
          not extend to claims that the creditor or releasing party does not
          know or suspect to exist in his or her favor at the time of executing
          the release and that, if known by him or her, would have materially
          affected his or her settlement with the debtor or released party.”
          <br />
          <br />
          19. Transfer and Processing Data
          <br />
          <br />
          In order for us to provide our Services, we may process, transfer and
          store information about you in the United States and other countries,
          where you may not have the same rights and protections as you do under
          local law.
          <br />
          <br />
          20. Dispute Resolution; Binding Arbitration
          <br />
          <br />
          Please read the following section carefully because it requires you to
          arbitrate certain disputes and claims with Fyat Lux and limits the
          manner in which you can seek relief from us, unless you opt out of
          arbitration by following the instructions set forth below. No class or
          representative actions or arbitrations are allowed under this
          arbitration provision. In addition, arbitration precludes you from
          suing in court or having a jury trial.
          <br />
          <br />
          20.1 No Representative Actions. You and Fyat Lux agree that any
          dispute arising out of or related to these Terms or our Services is
          personal to you and Fyat Lux and that any dispute will be resolved
          solely through individual action, and will not be brought as a class
          arbitration, class action or any other type of representative
          proceeding.
          <br />
          <br />
          20.2 Arbitration of Disputes. Except for small claims disputes in
          which you or Fyat Lux seeks to bring an individual action in small
          claims court located in the county of your billing address or disputes
          in which you or Fyat Lux seeks injunctive or other equitable relief
          for the alleged infringement or misappropriation of intellectual
          property, you and Fyat Lux waive your rights to a jury trial and to
          have any other dispute arising out of or related to these Terms or our
          Services, including claims related to privacy and data security,
          (collectively, “Disputes”) resolved in court. Instead, for any Dispute
          that you have against Fyat Lux you agree to first contact Fyat Lux and
          attempt to resolve the claim informally by sending a written notice of
          your claim (“Notice”) to Fyat Lux by email at notice@fyatlux.com or by
          certified mail addressed to Agents and Corporations, Inc., c/o 10KAF
          LLC, 1201 Orange Street, Suite 600, Wilmington, Delaware, 19801. The
          Notice must (a) include your name, residence address, email address,
          and telephone number; (b) describe the nature and basis of the
          Dispute; and (c) set forth the specific relief sought. Our notice to
          you will be similar in form to that described above. If you and Fyat
          Lux cannot reach an agreement to resolve the Dispute within thirty
          (30) days after such Notice is received, then either party may submit
          the Dispute to binding arbitration administered by JAMS or, under the
          limited circumstances set forth above, in court. All Disputes
          submitted to JAMS will be resolved through confidential, binding
          arbitration before one arbitrator. Arbitration proceedings will be
          held in the County of Middlesex in the Commonwealth of Massachusetts.
          You and Fyat Lux agree that Disputes will be held in accordance with
          the JAMS Streamlined Arbitration Rules and Procedures (“JAMS Rules”).
          The most recent version of the JAMS Rules are available on the JAMS
          website and are hereby incorporated by reference. You either
          acknowledge and agree that you have read and understand the JAMS Rules
          or waive your opportunity to read the JAMS Rules and waive any claim
          that the JAMS Rules are unfair or should not apply for any reason.
          <br />
          <br />
          20.3 You and Fyat Lux agree that these Terms affect interstate
          commerce and that the enforceability of this Section 20 will be
          substantively and procedurally governed by the Federal Arbitration
          Act, 9 U.S.C. § 1, et seq. (the “FAA”), to the maximum extent
          permitted by applicable law. As limited by the FAA, these Terms and
          the JAMS Rules, the arbitrator will have exclusive authority to make
          all procedural and substantive decisions regarding any Dispute and to
          grant any remedy that would otherwise be available in court, including
          the power to determine the question of arbitrability. The arbitrator
          may conduct only an individual arbitration and may not consolidate
          more than one individual’s claims, preside over any type of class or
          representative proceeding or preside over any proceeding involving
          more than one individual.
          <br />
          <br />
          20.4 The arbitration will allow for the discovery or exchange of
          non-privileged information relevant to the Dispute. The arbitrator,
          Fyat Lux, and you will maintain the confidentiality of any arbitration
          proceedings, judgments and awards, including information gathered,
          prepared and presented for purposes of the arbitration or related to
          the Dispute(s) therein. The arbitrator will have the authority to make
          appropriate rulings to safeguard confidentiality, unless the law
          provides to the contrary. The duty of confidentiality does not apply
          to the extent that disclosure is necessary to prepare for or conduct
          the arbitration hearing on the merits, in connection with a court
          application for a preliminary remedy or in connection with a judicial
          challenge to an arbitration award or its enforcement, or to the extent
          that disclosure is otherwise required by law or judicial decision.
          <br />
          <br />
          20.5 You and Fyat Lux agree that for any arbitration you initiate, you
          will pay the filing fee and you and Fyat Lux will split equally the
          remaining JAMS fees and costs; provided that if you are a consumer the
          maximum amount you will be required to pay for the filing fee is $250
          and Fyat Lux will pay any remaining amount of the filing fee and the
          remaining JAMS fees and costs. For any arbitration initiated by Fyat
          Lux, Fyat Lux will pay all JAMS fees and costs. You and Fyat Lux agree
          that the state or federal courts of the State of Massachusetts and the
          United States sitting in County of Middlesex in the Commonwealth of
          Massachusetts have exclusive jurisdiction over any appeals and the
          enforcement of an arbitration award.
          <br />
          <br />
          20.6 Any Dispute must be filed within one (1) year after the relevant
          claim arose; otherwise, the Dispute is permanently barred, which means
          that you and Fyat Lux will not have the right to assert the claim.
          <br />
          <br />
          20.7 You have the right to opt out of binding arbitration within 30
          days of the date you first accepted the terms of this Section 20 by
          emailing us at notice@fyatlux.com. In order to be effective, the
          opt-out notice must include your full name and address and clearly
          indicate your intent to opt out of binding arbitration. By opting out
          of binding arbitration, you are agreeing to resolve Disputes in
          accordance with Section 21.
          <br />
          <br />
          20.8 If any portion of this Section 20 is found to be unenforceable or
          unlawful for any reason, (a) the unenforceable or unlawful provision
          shall be severed from these Terms; (b) severance of the unenforceable
          or unlawful provision shall have no impact whatsoever on the remainder
          of this Section 20 or the parties’ ability to compel arbitration of
          any remaining claims on an individual basis pursuant to this Section
          20; and (c) to the extent that any claims must therefore proceed on a
          class, collective, consolidated, or representative basis, such claims
          must be litigated in a civil court of competent jurisdiction and not
          in arbitration, and the parties agree that litigation of those claims
          shall be stayed pending the outcome of any individual claims in
          arbitration. Further, if any part of this Section 20 is found to
          prohibit an individual claim seeking public injunctive relief, that
          provision will have no effect to the extent such relief is allowed to
          be sought out of arbitration, and the remainder of this Section 20
          will be enforceable.
          <br />
          <br />
          21. Governing Law and Venue
          <br />
          <br />
          Any dispute arising from these Terms and your use of our Services will
          be governed by and construed and enforced in accordance with the laws
          of the State of Massachusetts without regard to conflict of law rules
          or principles that would cause the application of the laws of any
          other jurisdiction. Any dispute between the parties that is not
          subject to arbitration or cannot be heard in small claims court will
          be resolved in the state or federal courts located in the County of
          Middlesex in the Commonwealth of Massachusetts and you consent to
          jurisdiction and venue in such courts.
          <br />
          <br />
          22. Modifying and Terminating our Services
          <br />
          <br />
          We reserve the right to modify our Services or to suspend or stop
          providing all or portions of our Services at any time. You also have
          the right to stop using our Services at any time. We are not
          responsible for any loss or harm related to your inability to access
          or use our Services.
          <br />
          <br />
          23. Severability
          <br />
          <br />
          If any provision or part of a provision of these Terms is unlawful,
          void or unenforceable, that provision or part of the provision is
          deemed severable from these Terms and does not affect the validity and
          enforceability of any remaining provisions.
          <br />
          <br />
          24. Relationship of these Terms and Digital Collectible Terms
          <br />
          <br />
          Digital Collectible Terms may be entered between you and Fyat Lux
          (“Fyat Lux Collectible Terms”) or between you and the applicable Third
          Party Developer (“Third Party Collectible Terms”). Fyat Lux
          Collectible Terms, and any other terms that may be provided with or
          that you may otherwise agree to in connection with any particular
          Digital Collectible or Service, are incorporated into, and made a part
          of, these Terms by this reference. Fyat Lux is not a party to any
          Third Party Collectible Terms and will not have any responsibility or
          liability for, arising out of, or relating to any Third Party
          Collectible Terms.
          <br />
          <br />
          Notwithstanding anything else in any Digital Collectible Terms, these
          Terms supplement the Digital Collectible Terms and these Terms will
          apply to any exercise of any license in or to any Related Content, any
          transfer of any Digital Collectible or any other use or disposition of
          any Digital Collectible; provided that in the event of any conflict or
          inconsistency between these Terms and any Digital Collectible Terms,
          the Digital Collectible Terms will govern. For avoidance of doubt, and
          notwithstanding anything else in these Terms or any Digital
          Collectible Terms, if you breach any obligation that you may have to
          make payments to Fyat Lux pursuant to any Digital Collectible Terms or
          these Terms, such breach shall constitute a breach of any and all of
          these Terms and any applicable Digital Collectible Terms.
          <br />
          <br />
          Subject to the foregoing provisions of this Section 24, these Terms
          constitute the entire agreement between you and Fyat Lux relating to
          your access to and use of our Services.
          <br />
          <br />
          25. Miscellaneous
          <br />
          <br />
          Notwithstanding any other provisions of these Terms, Sections 6
          through 25 survive any expiration or termination of these terms. The
          failure of Fyat Lux to exercise or enforce any right or provision of
          these Terms will not operate as a waiver of such right or provision.
          The section titles in these Terms are for convenience only and have no
          legal or contractual effect. Except as otherwise provided herein,
          these Terms are intended solely for the benefit of the parties and are
          not intended to confer third-party beneficiary rights upon any other
          person or entity. You agree that communications and transactions
          between us may be conducted electronically. These Terms may not be
          transferred, assigned or delegated by you, by operation of law or
          otherwise, without our prior written consent, and any attempted
          transfer, assignment or delegation without such consent will be void
          and without effect. We may freely transfer, assign or delegate these
          Terms or our Services, in whole or in part, without your prior written
          consent.
        </SMainText>
      </STermsContainer>
    </SContainer>
  );
};

export default TermsOfService;
