import React, { useEffect, useRef, useState } from 'react'

import { filterMap, findMap } from '../utils'

import './ScrollView.scss'

import movie from '../assets/Monolith_Video_No_Zoom_No_Logo.mp4'; 
import poster from '../assets/Monolith_Video_No_Zoom_No_Logo.jpg'; 

export default function ScrollView(props: {
    foreground: Array<{ exit: number, style?: React.CSSProperties, children?: React.ReactNode }>,
    background: Array<{ enter: number, exit: number, style?: React.CSSProperties }>,
}) {
    const foreground = props.foreground.sort((a, b) => a.exit - b.exit)
    const background = props.background
    const progress = useProgress()

    const size = Math.max(...foreground.map(x => x.exit).concat(background.map(x => x.exit)))

    return (
        <div className='scrollview' style={{ height: `${100 * size}vh` }}>
            {filterMap(background, (x, i) => {
                const k = background.length - 1 - i
                
                if (k === 0)
                {
                    return x.enter <= progress && x.exit + 1 > progress
                        ?
                        <div key={k} className={`bg bg${k}`} style={{ zIndex: k, ...x.style }}>
                            <video autoPlay loop muted poster={poster} className="heroVideo">
                                <source src={movie} type='video/mp4' />
                                <source src={movie} type="video/ogg" /> 
                            </video>
                        </div>
                        : null
                }
                else
                {
                    return x.enter <= progress && x.exit + 1 > progress
                        ? <div key={k} className={`bg bg${k}`} style={{ zIndex: k, ...x.style }} />
                        : null
                }
            })}
            {findMap(foreground, (x, i) =>
                x.exit >= progress
                    ? <div
                        key={i + background.length}
                        className='fg'
                        style={{ zIndex: background.length, ...x.style }}>{x.children}</div>
                    : null
            )}
        </div>
    )
}

export function useProgress() {
    const el = document.documentElement
    const [progress, setProgress] = useState(el.scrollTop / el.clientHeight)
    const frame = useRef(0)
    useEffect(function next() {
        setProgress(el.scrollTop / el.clientHeight)
        frame.current = window.requestAnimationFrame(next)
        return () => window.cancelAnimationFrame(frame.current)
    }, [el.scrollTop, el.clientHeight])
    return progress
}