export const FLUX_CONTRACT_TEST = {
    1: {
    constant: false,
    address: '0x14C4471A7F6dCAc4F03a81Ded6253eacEfF15B3d',
    abi: [
      {
        inputs: [
          {
            name: "owner",
            type: "address"
          }
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "",
            type: "uint256"
          }
        ],
        stateMutability: "view",
        type: "function",
        constant: true
     },
     {
            inputs: [
              {
                name: "owner",
                type: "address"
              },
              {
                name: "index",
                type: "uint256"
              }
            ],
            name: "tokenOfOwnerByIndex",
            outputs: [
              {
                name: "",
                type: "uint256"
              }
            ],
            stateMutability: "view",
            type: "function",
            constant: true
      },
      {
        inputs: [],
        name: "PRICE",
        outputs: [
          {
            name: "",
            type: "uint256"
          }
        ],
        stateMutability: "view",
        type: "function",
        constant: true
      },
      {
        inputs: [
          {
            name: "tokenId",
            type: "uint256"
          }
        ],
        name: "tokenURI",
        outputs: [
          {
            name: "",
            type: "string"
          }
        ],
        stateMutability: "view",
        type: "function",
        constant: true
      }
    ]
    },
  4: {
    constant: false,
    address: '0xf1fC0de4ef6E447afad99179187Bc7a2C44AC28B',
    abi: [
        {
              inputs: [
                {
                  name: "owner",
                  type: "address"
                }
              ],
              name: "balanceOf",
              outputs: [
                {
                  name: "",
                  type: "uint256"
                }
              ],
              stateMutability: "view",
              type: "function",
              constant: true
           },
        {
              inputs: [
                {
                  name: "owner",
                  type: "address"
                },
                {
                  name: "index",
                  type: "uint256"
                }
              ],
              name: "tokenOfOwnerByIndex",
              outputs: [
                {
                  name: "",
                  type: "uint256"
                }
              ],
              stateMutability: "view",
              type: "function",
              constant: true
         },
      {
        inputs: [],
        name: "PRICE",
        outputs: [
          {
            name: "",
            type: "uint256"
          }
        ],
        stateMutability: "view",
        type: "function",
        constant: true
      },
      {
        inputs: [
          {
            name: "tokenId",
            type: "uint256"
          }
        ],
        name: "tokenURI",
        outputs: [
          {
            name: "",
            type: "string"
          }
        ],
        stateMutability: "view",
        type: "function",
        constant: true
      }
    ]
    }
}

export const FLUX_WORLD_CONTRACT = {
  1: {
  constant: false,
  address: '0xE079fa5CE01D1dC55AeA39317219825f2D7A3193',
  abi: [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "id",
        "type": "uint256"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [
      {
        "internalType": "address[]",
        "name": "accounts",
        "type": "address[]"
      },
      {
        "internalType": "uint256[]",
        "name": "ids",
        "type": "uint256[]"
      }
    ],
    "name": "balanceOfBatch",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "id",
        "type": "uint256"
      }
    ],
    "name": "uri",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "edition",
        "type": "uint256"
      }
    ],
    "name": "getEditionSize",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  },  
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "edition",
        "type": "uint256"
      }
    ],
    "name": "getIsEditionAvailable",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
  }
]
}}