import React from 'react'
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";

import './CollectionPage.scss'

export default function CollectionPage(props: { logAnalytics: (eventName: string) => void }) {
    const location = useLocation();

    useEffect(() => {
        console.log("collection page");
        props.logAnalytics("collection page");
    }, [location]);

    return (
        <div className='collection-page'>
            <h1>My Collection</h1>
            <div className='content'>
                <div className='filter'>
                    <h2>Filter</h2>
                    <div className='items'>
                        <span>Accessory</span>
                        <button><img src='src/plus.svg' alt='+' /></button>
                    </div>
                </div>
                <div className='cards'>
                    <div className='view-switcher' />
                    <div className='content' />
                </div>
            </div>
        </div>
    )
}