import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const SWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const Wrapper = (props: any) => {
  const { children } = props
  return (
    <SWrapper>
      {children}
    </SWrapper>
  )
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default Wrapper
