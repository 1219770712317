import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { apiGetVotaCheckoutLink } from "src/helpers/api";
import QuantityPicker from "../components/QuantityPicker";
import "./VaultStorePage.scss";

import movie from "../assets/Website_Video.mp4";
import poster from "../assets/website-video-poster.jpg";
import { discordUrl, twitterUrl, instagramUrl } from "../App";

interface IStoreProps {
  connectWallet: () => void;
  logAnalytics: (eventName: string) => void;
  numWorldTokens: number[];
}
const price_phygital = 33;
const price_holder = 10;
const price_bundle_discount = -33;
const starting_quantity = 0;

const VaultStorePage = (props: IStoreProps) => {
  const { logAnalytics, numWorldTokens } = props;

  const [NinezQuantity, setNinezQuantity] = useState(starting_quantity);
  const [NullQuantity, setNullQuantity] = useState(starting_quantity);
  const [DeeQuantity, setDeeQuantity] = useState(starting_quantity);
  const [DumQuantity, setDumQuantity] = useState(starting_quantity);
  const [HolderQuantity, setHolderQuantity] = useState(starting_quantity);
  const [IsCheckingOut, setIsCheckingOut] = useState(false);

  function getTotal() {
    const NinesTotal = price_phygital * NinezQuantity;
    const NullTotal = price_phygital * NullQuantity;
    const DumTotal = price_phygital * DumQuantity;
    const DeeTotal = price_phygital * DeeQuantity;
    const HolderTotal = price_holder * HolderQuantity;

    return (
      NinesTotal +
      NullTotal +
      DumTotal +
      DeeTotal +
      HolderTotal +
      getBundleDiscount()
    );
  }

  function getBundleDiscount() {
    const items = [NinezQuantity, NullQuantity, DumQuantity, DeeQuantity];
    return Math.min(...items) * price_bundle_discount;
  }

  async function checkout() {
    console.log("checking out");

    const items = [
      NinezQuantity,
      NullQuantity,
      DumQuantity,
      DeeQuantity,
      HolderQuantity,
    ];

    if (IsCheckingOut) {
      return;
    }

    try {
      // get checkout link
      logAnalytics("vota checkout");
      setIsCheckingOut(true);
      const votaLink = await apiGetVotaCheckoutLink(items, 1);
      console.log(votaLink);
      const parsedLink = JSON.parse(JSON.stringify(votaLink));
      console.log(parsedLink.link);
      window.location.replace(parsedLink.link);
    } catch (error) {
      logAnalytics("vota checkout error");
      console.error(error);
    }
  }

  const location = useLocation();

  useEffect(() => {
    logAnalytics("vault store page");
  }, [location]);

  // const videoOptions  = {
  //   playerVars: {
  //     autoplay: 1,
  //     controls: 0,
  //     rel: 0,
  //     showinfo: 0,
  //     mute: 1,
  //     loop: 1
  //   }

  return (
    <div className="vault-page">
      {false ? ( // numWorldTokens.length !== 0 ? (
        <div className="vault-fg">
          <div className="marketing-section flex-column">
            <div className="vault-text">
              <h1>Vault of the Ancients Phygitals</h1>
              <p>
                From <b>Nov. 14 to Nov. 28, 2022</b>, Fyat Lux World NFT owners
                can order an exclusive phygital smart card for their Vault of
                the Ancients 3D collectibles (Ninez, Null, Tweedle-Dee or
                Tweedle-Dum). For fans of Fyat Lux, these limited edition
                phygitals unlock new modes of interacting with their
                collectibles in AR, including Card Portal Mode, Duality Mode,
                and two exclusive new features: Pedestal Mode and Adventure
                Mode!
              </p>
            </div>
            <div className="videoplayer-section">
              <video autoPlay loop muted poster={poster} className="storeVideo">
                <source src={movie} type="video/mp4" />
                <source src={movie} type="video/ogg" />
              </video>
            </div>
          </div>
          <div className="vault-listing-section">
            <h3>Select your phygitals</h3>
            <BundleListing
              image={"-bundle"}
              title={"Ninez Collection Discount"}
              description1={
                "Purchase one of each phygital to get a $33 discount"
              }
              discount={getBundleDiscount()}
              disabled={Math.min(...numWorldTokens) === 0}
            />
            <Listing
              setQuantity={setNinezQuantity}
              image={"-ninez"}
              title={"Ninez"}
              description1={""}
              description2={"(Holder not included)"}
              price={price_phygital}
              quantity={NinezQuantity}
              disabled={numWorldTokens[0] <= 0}
              openSeaLink={
                "https://opensea.io/assets/ethereum/0xe079fa5ce01d1dc55aea39317219825f2d7a3193/1"
              }
            />
            <Listing
              setQuantity={setNullQuantity}
              image={"-null"}
              title={"Null"}
              description1={""}
              description2={"(Holder not included)"}
              price={price_phygital}
              quantity={NullQuantity}
              disabled={numWorldTokens[1] <= 0}
              openSeaLink={
                "https://opensea.io/assets/ethereum/0xe079fa5ce01d1dc55aea39317219825f2d7a3193/2"
              }
            />
            <Listing
              setQuantity={setDumQuantity}
              image={"-dum"}
              title={"Tweedle-Dum"}
              description1={""}
              description2={"(Holder not included)"}
              price={price_phygital}
              quantity={DumQuantity}
              disabled={numWorldTokens[2] <= 0}
              openSeaLink={
                "https://opensea.io/assets/ethereum/0xe079fa5ce01d1dc55aea39317219825f2d7a3193/3"
              }
            />
            <Listing
              setQuantity={setDeeQuantity}
              image={"-dee"}
              title={"Tweedle-Dee"}
              description1={""}
              description2={"(Holder not included)"}
              price={price_phygital}
              quantity={DeeQuantity}
              disabled={numWorldTokens[3] <= 0}
              openSeaLink={
                "https://opensea.io/assets/ethereum/0xe079fa5ce01d1dc55aea39317219825f2d7a3193/4"
              }
            />
            <Listing
              setQuantity={setHolderQuantity}
              image={"-holder"}
              title={"Phygital Holder"}
              description1={""}
              description2={
                "Show off your phygital with the official Fyat Lux holder!"
              }
              price={price_holder}
              quantity={HolderQuantity}
            />
            <div className="totals-section">
              <div className="totals-shipping">
                <p>
                  Shipping: <i>Calculated at checkout</i>
                </p>
              </div>
              <div
                className={
                  "totals-final" + (IsCheckingOut ? " checking-out" : "")
                }>
                <div className="subtotal">
                  <b>SUBTOTAL:</b> ${getTotal()}
                </div>
                {getTotal() > 0 ? (
                  <button
                    onClick={checkout}
                    className={
                      IsCheckingOut ? "checking-out checkout" : "checkout"
                    }>
                    {IsCheckingOut ? "" : "Checkout"}
                  </button>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="vault-fg">
          <div className="marketing-section flex-column">
            <div className="vault-text">
              <h1>SIGN UP FOR THE NEXT PHYGITAL DROP!</h1>
              <p>
                The Vault of the Ancients phygital order window is closed.
                Follow our official social channels (
                <a href={twitterUrl}>Twitter</a>,{" "}
                <a href={discordUrl}>Discord</a>,{" "}
                <a href={instagramUrl}>Instagram</a>) and sign up for our email
                newsletter below to learn about the next phygital product drop
                when it is announced!
              </p>
            </div>
            <div className="newsletter">
              <br />
              <iframe
                width="100%"
                height="100%"
                src="https://db99a2bb.sibforms.com/serve/MUIEAEgzQhRfKKTtcAmgWsH8WRp2PUIqRNtfaxi5a7zoOUhsA9KwQC0Zw0JBhioX9GqVkrMyDLg5p4zZBRAvVhkFPK_pMX-5S_FIHmyLoTsHnq9KJ23Z0NnK3UUEEinCe8YZgWKFMWZAOOmSAfA0jiFCpt18S6pfR_YDLTs3zeNKm2KtSa1479FtiwYKlxB__jy6hiM-wYDWTDsU"
                frameBorder="0"
                allowFullScreen
              />
            </div>
            {/* <div className="wallet-connect-section">
              <div className="wallet-connect-text">
                Your wallet must be connected in order to purchase
              </div>
              <div className="wallet-connect-button">
                <button onClick={connectWallet}>Connect Wallet</button>
              </div>
            </div> */}
          </div>
          <div className="videoplayer-section-disconnected">
            <video autoPlay loop muted className="storeVideo">
              <source src={movie} type="video/mp4" />
              <source src={movie} type="video/ogg" />
            </video>
          </div>
        </div>
      )}
      <div className="vault-bg3" />
    </div>
  );
};

interface IListingProps {
  setQuantity: (value: number) => void;
  image: string;
  title: string;
  description1?: string;
  description2?: string;
  price: number;
  quantity: number;
  disabled?: boolean;
  openSeaLink?: string;
}

interface IBundleListingProps {
  image: string;
  title: string;
  description1?: string;
  description2?: string;
  discount: number;
  disabled?: boolean;
}
function BundleListing(props: IBundleListingProps) {
  return (
    <>
      <div className="vault-listing">
        <div
          className={
            props.image + (props.disabled ? " disabled" : "") + " listing-image"
          }
        />
        <div
          className={
            (props.disabled ? " disabled" : "") + " listing-text-section"
          }>
          <div className="listing-title">{props.title}</div>
          <div className="listing-description2">{props.description1}</div>
          <div className="listing-description2">{props.description2}</div>
        </div>
        <div className="empty-space" />
        <div className="empty-space" />
        {props.disabled ? (
          <div className="quantity-disabled">
            You must own all the NFTs in this collection in order to be eligible
            for discount. You can purchase them on
            <a href="https://opensea.io/collection/fyat-lux-world"> OpenSea</a>
          </div>
        ) : props.discount < 0 ? (
          <div className="bundle-applied">
            <div className="listing-total bundle-active">
              -${-props.discount}
            </div>
            <div className="bundle-applied-text">Discount applied!</div>
          </div>
        ) : (
          <div className="listing-total">$0</div>
        )}
      </div>
    </>
  );
}

function Listing(props: IListingProps) {
  return (
    <>
      <div className="vault-listing">
        <div
          className={
            props.image + (props.disabled ? " disabled" : "") + " listing-image"
          }
        />
        <div
          className={
            (props.disabled ? " disabled" : "") + " listing-text-section"
          }>
          <div className="listing-title">{props.title}</div>
          <div className="listing-description">
            {props.description1}
            <b>${props.price}</b>
          </div>
          <div className="listing-description2">{props.description2}</div>
        </div>
        {props.disabled ? (
          <>
            <div className="empty-space" />
            <div className="quantity-disabled">
              You must own this NFT in order to collect the phygital. You can
              purchase one on
              <a href={props.openSeaLink}> OpenSea</a>
            </div>
          </>
        ) : (
          <>
            <QuantityPicker
              min={0}
              max={999}
              quantity={starting_quantity}
              onChange={(v: number) => props.setQuantity(v)}
            />
            <div className="listing-total">${props.price * props.quantity}</div>
          </>
        )}
      </div>
    </>
  );
}

export default VaultStorePage;
