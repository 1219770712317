import * as React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const SContainer = styled.div`
  width: 100vw;
  min-width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
  align-self: center;
`;

const STermsContainer = styled.div`
  height: 100%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  background-color: #f2eeec;
  justify-content: flex-start;
  padding: 100px 48px 32px;
`;

const SHeaderText = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
`;

const SMainText = styled.h4`
  max-width: 70vw;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  align-self: center;
  text-align: left;
`;

const Privacy = (props: { logAnalytics: (eventName: string) => void }) => {
  const location = useLocation();

  useEffect(() => {
    console.log("privacy page");
    props.logAnalytics("privacy page");
  }, [location]);

  return (
    <SContainer>
      <STermsContainer>
        <SHeaderText>PRIVACY POLICY</SHeaderText>
        <SMainText>
          <b>Last Updated: 28 December 2023</b>
          <br />
          <br />
          <br />
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from
          www.fyatlux.com (the “Site”).
          <br />
          <br />
          PERSONAL INFORMATION WE COLLECT
          <br />
          <br />
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. We refer to this
          automatically-collected information as “Device Information.”
          <br />
          <br />
          We collect Device Information using the following technologies:
          <br />- “Cookies” are data files that are placed on your device or
          computer and often include an anonymous unique identifier. For more
          information about cookies, and how to disable cookies, visit
          http://www.allaboutcookies.org.
          <br />- “Log files” track actions occurring on the Site, and collect
          data including your IP address, browser type, Internet service
          provider, referring/exit pages, and date/time stamps.
          <br />- “Web beacons,” “tags,” and “pixels” are electronic files used
          to record information about how you browse the Site.
          <br />
          <br />
          Additionally when you make a purchase or attempt to make a purchase
          through the Site, we collect certain information from you, including
          your name, billing address, shipping address, payment information
          (including credit card numbers, email address, Metamask, Eth address
          and phone number. We refer to this information as “Order Information.”
          <br />
          <br />
          When we talk about “Personal Information” in this Privacy Policy, we
          are talking both about Device Information and Order Information.
          <br />
          <br />
          HOW DO WE USE YOUR PERSONAL INFORMATION?
          <br />
          <br />
          We use the Order Information that we collect generally to fulfill any
          orders placed through the Site (including processing your payment
          information, arranging for shipping, and providing you with invoices
          and/or order confirmations). Additionally, we use this Order
          Information to: Communicate with you;
          <br />
          <br />
          Screen our orders for potential risk or fraud; and When in line with
          the preferences you have shared with us, provide you with information
          or advertising relating to our products or services. We use the Device
          Information that we collect to help us screen for potential risk and
          fraud (in particular, your IP address), and more generally to improve
          and optimize our Site (for example, by generating analytics about how
          our customers browse and interact with the Site, and to assess the
          success of our marketing and advertising campaigns, where applicable).
          <br />
          <br />
          SHARING YOUR PERSONAL INFORMATION
          <br />
          <br />
          We share your Personal Information with third parties to help us use
          your Personal Information, as described above. For example, we use
          Google Analytics to help us understand how our customers use the
          Site--you can read more about how Google uses your Personal
          Information here: https://www.google.com/intl/en/policies/privacy/.
          You can also opt-out of Google Analytics here:
          https://tools.google.com/dlpage/gaoptout. Additionally, we may share
          your Personal Information with third party providers of shipping
          services and other order fulfillment services to fulfill orders placed
          on the Site (i.e. for shipment of a Digital Collectible’s physical
          Card).
          <br />
          <br />
          We may transfer or assign this Privacy Policy and any Personal
          Information to a third party entity in connection with a merger,
          acquisition, consolidation, restructuring, the sale of substantially
          all of our shares and/or assets, in connection with bankruptcy
          proceedings, reorganization, or other change of control events.
          <br />
          <br />
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights.
          <br />
          <br />
          TrueDepth API
          <br />
          <br />
          Our apps make use of automatically collected information using the
          device camera and the TrueDepth API provided by Apple. The only use of
          this information is to track the user’s head and face so that the user
          can control the AR experience and the selfie filters by moving their
          device and their head. None of the information collected by the
          TrueDepth API ever leaves the user's device nor is it persistently
          stored on the device.
          <br />
          <br />
          DO NOT TRACK
          <br />
          <br />
          Please note that we do not alter our Site’s data collection and use
          practices when we see a Do Not Track signal from your browser.
          <br />
          <br />
          YOUR RIGHTS
          <br />
          <br />
          If you are a European resident, you have the right to access personal
          information we hold about you and to ask that your personal
          information be corrected, updated, or deleted. If you would like to
          exercise this right, please contact us through the contact information
          below.
          <br />
          <br />
          Additionally, if you are a European resident we note that we are
          processing your information in order to fulfill contracts we might
          have with you (for example if you make an order through the Site), or
          otherwise to pursue our legitimate business interests listed above.
          Additionally, please note that your information will be transferred
          outside of Europe, including to Canada and the United States.
          <br />
          <br />
          DATA RETENTION
          <br />
          <br />
          When you place an order through the Site, we will maintain your Order
          Information for our records unless and until you ask us to delete this
          information.
          <br />
          <br />
          MINORS
          <br />
          <br />
          The Site is not intended for individuals under the age of 18.
          <br />
          <br />
          CHANGES
          <br />
          <br />
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </SMainText>
      </STermsContainer>
    </SContainer>
  );
};

export default Privacy;
