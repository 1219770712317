import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

import "./GlimpsesPage.scss";

export default function GlimpsesPage(props: {
  logAnalytics: (eventName: string) => void;
}) {
  const location = useLocation();

  useEffect(() => {
    console.log("glimpse page");
    props.logAnalytics("glimpse page");
  }, [location]);

  return (
    <div className="glimpses-page">
      <div className="glimpses-fg">
        <div className="text">
          <h1>Glimpses</h1>
          <p>
            The world of Fyat Lux extends far beyond the characters and plot of
            the main story. Read official glimpses into the past, present, and
            future of this world - all created by an amazing and ever-expanding
            list of storytellers and artists.
          </p>
          <p>
            Released under{" "}
            <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/">
              Creative Commons BY-NC-SA
            </a>
          </p>
        </div>
        <div className="items">
          <div className="item">
            <div
              className="item-image"
              style={{ backgroundImage: "url(/glimpses1.png)" }}
            />
            <h1>A Spark Must Never Show It's Face</h1>
            <Link to={"/glimpse-details/0"}>
              <button>Read Now</button>
            </Link>
          </div>
          <div className="item">
            <div
              className="item-image"
              style={{ backgroundImage: "url(/glimpses2.png)" }}
            />
            <h1>Could a Machine Even Aspire to Freedom</h1>
            <Link to={"/glimpse-details/1"}>
              <button>Read Now</button>
            </Link>
          </div>
          <div className="item">
            <div
              className="item-image"
              style={{ backgroundImage: "url(/glimpses3.png)" }}
            />
            <h1>A Spark's Gotta Make A Living</h1>
            <Link to={"/glimpse-details/2"}>
              <button>Read Now</button>
            </Link>
          </div>
          <div className="item">
            <div
              className="item-image"
              style={{ backgroundImage: "url(/glimpses4.png)" }}
            />
            <h1>Where Nature Ends and Manmade Structures Begin</h1>
            <Link to={"/glimpse-details/3"}>
              <button>Read Now</button>
            </Link>
          </div>
          <div className="item">
            <div
              className="item-image"
              style={{ backgroundImage: "url(/glimpses5.png)" }}
            />
            <h1>Through Her Chtara's Visor</h1>
            <Link to={"/glimpse-details/4"}>
              <button>Read Now</button>
            </Link>
          </div>
          <div className="item">
            <div
              className="item-image"
              style={{ backgroundImage: "url(/glimpses6.png)" }}
            />
            <h1>The Silhouette of Art3mis</h1>
            <Link to={"/glimpse-details/5"}>
              <button>Read Now</button>
            </Link>
          </div>
          <div className="item">
            <div
              className="item-image"
              style={{ backgroundImage: "url(/glimpses7.png)" }}
            />
            <h1>Scar Tissue of the Great Sin</h1>
            <Link to={"/glimpse-details/6"}>
              <button>Read Now</button>
            </Link>
          </div>
          <div className="item">
            <div
              className="item-image"
              style={{ backgroundImage: "url(/glimpses8.png)" }}
            />
            <h1>Scar Tissue of the Great Sin</h1>
            <Link to={"/glimpse-details/7"}>
              <button>Read Now</button>
            </Link>
          </div>
          <div className="item">
            <div
              className="item-image"
              style={{ backgroundImage: "url(/glimpses9.png)" }}
            />
            <h1>A Symbol of the Revolution</h1>
            <Link to={"/glimpse-details/8"}>
              <button>Read Now</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="glimpses-bg1" />
    </div>
  );
}
