import * as React from "react";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import model_image from "../assets/model-3d.png";
import tposed_image from "../assets/t-posed.png";
import { saveAs } from "file-saver";

import "../theme.scss";

const STopContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2eeec;
`;

const SMainContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 100px;
`;

const SViewerContainer = styled.div`
  height: 85vh;
  width: 60vw;
  min-width: 500px;
  max-width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;
  align-self: flex-start;
  margin: 25px 75px;
  //flex: 2 1;

  @media (min-width: 900px) {
    position: sticky;
    top: 150px;
  }

  @media (max-width: 720px) {
    min-width: 85vw;
  }
`;

const SContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  max-width: 30vw;
`;

const SSparkContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  align-self: flex-start;
  margin: 10px 0px;
  margin-bottom: 20px;
`;

const SInfoText = styled.h3`
  font-family: "Lexend";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: rgb(30, 30, 30);
  text-align: center;
  margin-bottom: 0;
  text-transform: uppercase;
`;

const STokenIDText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 1;
  color: rgb(30, 30, 30);
  text-align: left;
  padding-bottom: 20px;
`;

const SMainInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SMainInfoText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 38px;
  color: rgb(30, 30, 30);
  text-align: left;
  padding-right: 20px;
`;

const SExternalInfo = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;

  a {
    color: #303030;
    text-decoration: none;
    padding-right: 20px;
  }
`;

const STraitTopContainer = styled.div`
  //width: 45vw;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 0px;
`;

const STraitContainer = styled.div`
  max-height: 100%;
  padding: 10px 0 10px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

const STrait = styled.div`
  min-width: 235px;
  background-color: rgb(140, 143, 145);
  color: white;
  border-radius: 4px;
  border: 2px solid color rgb(0, 0, 0);
  padding: 8px;
  margin: 8px 5px;
`;

const STraitType = styled.div`
  color: rgb(230, 230, 230);
  font-size: 16px;
`;

const STraitValue = styled.div`
  color: rgb(30, 30, 30);
  font-size: 20px;
`;

const SDownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 30px;
`;

const SSectionTitle = styled.span`
  color: rgb(30, 30, 30);
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
  padding: 50px 0 10px 0;
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 26px;
    line-height: 1;
  }
`;

const SDownloadImage = styled.img`
  width: 15vw;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 4px;

  @media (max-width: 720px) {
    width: 40vw;
  }
`;

const SReturnButton = styled.div`
  padding: 0;
  font-size: 3vw;
  margin: 0 10px 20px;
  align-self: flex-start;
  z-index: 100;
  color: rgb(30, 30, 30);
  cursor: pointer;
`;

const SDownloadLink = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #b1b1b1;
  cursor: pointer;
  text-align: center;
`;

const SIFrame = styled.iframe`
  z-index: 100;
  border-radius: 4px;
  max-width: 80vw;
  padding: 20px;
`;

const SVideo = styled.video`
  max-height: 70vh;
  padding-top: 50px;
`;

const SRevealedContainer = styled.div``;

const saveFile = (link: string, fileName: string) => {
  saveAs(link, fileName);
};

interface IOverviewProps {
  metadata: any;
  returnToSelectorFunc: () => void;
  tokenId: number;
}

const TokenDetails = (props: IOverviewProps) => {
  const { metadata, returnToSelectorFunc, tokenId } = props;

  console.log(metadata);
  console.log(tokenId);

  let tokens_revealed = false;

  if (metadata.name !== "Network Error") {
    tokens_revealed = true;
  }

  return (
    <>
      <STopContainer>
        <SMainContainer>
          <SViewerContainer>
            <SReturnButton onClick={returnToSelectorFunc}>
              RETURN TO COLLECTION
            </SReturnButton>

            {tokens_revealed ? (
              <SIFrame
                src={"https://fyatlux-world-viewer.web.app/?tokenID=" + tokenId}
                width="100%"
                height="100%"
                frameBorder="0">
                Browers not compatible
              </SIFrame>
            ) : (
              <SVideo src={metadata.image} autoPlay loop />
            )}
          </SViewerContainer>
          <SContentContainer>
            <SMainInfoContainer>
              <STokenIDText>{metadata.name}</STokenIDText>
              <SMainInfoText>Fyat Lux</SMainInfoText>
              <SExternalInfo>
                <a
                  href={
                    "https://opensea.io/assets/ethereum/0xE079fa5CE01D1dC55AeA39317219825f2D7A3193/" +
                    tokenId
                  }>
                  VIEW ON OPENSEA
                </a>
                <a
                  href={
                    "https://etherscan.io/nft/0xE079fa5CE01D1dC55AeA39317219825f2D7A3193/" +
                    tokenId
                  }>
                  VIEW ON ETHERSCAN
                </a>
              </SExternalInfo>
            </SMainInfoContainer>
            <SSectionTitle>Traits</SSectionTitle>
            <STraitTopContainer>
              <STraitContainer>
                {metadata.attributes.map((d: any, i: number) => (
                  <STrait key={`${i}`}>
                    <STraitType>{d.trait_type}</STraitType>
                    <STraitValue>{d.value}</STraitValue>
                  </STrait>
                ))}
              </STraitContainer>
            </STraitTopContainer>

            {tokens_revealed ? (
              <SRevealedContainer>
                <SSectionTitle>2D DOWNLOADS</SSectionTitle>
                <SDownloadContainer>
                  <SSparkContainerImage>
                    <SDownloadImage
                      src={metadata.image}
                      alt={"image of " + metadata.name}
                    />
                    <SInfoText>Card Image</SInfoText>
                    <SDownloadLink
                      onClick={() =>
                        saveFile(
                          metadata.image,
                          "Card-" + metadata.name + ".jpg"
                        )
                      }>
                      Download Card Image
                    </SDownloadLink>
                  </SSparkContainerImage>
                </SDownloadContainer>

                <SSectionTitle>3D DOWNLOADS</SSectionTitle>
                <SDownloadContainer>
                  <SSparkContainerImage>
                    <SDownloadImage
                      src={model_image}
                      alt={"image of 3d model"}
                    />
                    <SInfoText>3D Model</SInfoText>
                    <SDownloadLink
                      onClick={() =>
                        saveFile(metadata.model, metadata.name + ".glb")
                      }>
                      Download 3D Model
                    </SDownloadLink>
                  </SSparkContainerImage>

                  <SSparkContainerImage>
                    <SDownloadImage
                      src={tposed_image}
                      alt={"image of 3d model"}
                    />
                    <SInfoText>T-Posed 3D Model</SInfoText>
                    <SDownloadLink
                      onClick={() =>
                        saveFile(metadata.rig, metadata.name + "-tpose.glb")
                      }>
                      Download T-posed 3D Model
                    </SDownloadLink>
                  </SSparkContainerImage>
                </SDownloadContainer>
              </SRevealedContainer>
            ) : (
              ""
            )}
          </SContentContainer>
        </SMainContainer>
      </STopContainer>
    </>
  );
};

TokenDetails.propTypes = {
  wallet_id: PropTypes.string,
};

export default TokenDetails;
