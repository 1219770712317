import * as React from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import CollectionPage from "./pages/CollectionPage";
import MainPage, { clip } from "./pages/MainPage";
import GlimpsesPage from "./pages/GlimpsesPage";
import GlimpseDetailPage from "./pages/GlimpseDetailPage";
import VaultPage from "./pages/VaultPage";
import VaultStorePage from "./pages/VaultStorePage";
import MyCollectionPage from "./pages/MyCollection";
import VotePage from "./pages/VotePage";
import VoteFinished from "./pages/VoteFinishedPage";
import Header from "./pages/Header";
import Wrapper from "./components/wrapper";
import ScrollToTop from "./components/ScrollToTop";
import styled from "styled-components";

import "./App.scss";

import { IAssetData } from "./helpers/types";
import { getChainData } from "./helpers/utilities";
import * as secret from "./secret.json";

import { apiGetAccountAssets, apiGetVotingPower } from "./helpers/api";
import { getCollectibleContract, getFluxWorldContract } from "./helpers/web3";

// @ts-ignore
import Web3 from "web3";
import Web3Modal from "web3modal";
import { WalletLink } from "walletlink";

// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
import Fortmatic from "fortmatic";

import metamaskLogo from "./assets/metamask.png";
import coinbaseLogo from "./assets/cb.png";
import MetaMaskOnboarding from "@metamask/onboarding";
import detectEthereumProvider from "@metamask/detect-provider";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import Privacy from "./pages/Privacy";
import MarketingPolicy from "./pages/MarketingPolicy";
import TermsOfService from "./pages/TermsOfService";
import DigitalCollectibleTerms from "./pages/DigitalCollectibleTerms";
import Modal from "./components/Modal";
import Loader from "./components/Loader";

export const discordUrl = "https://discord.gg/fyat-lux-894777019827625984";
export const twitterUrl = "https://twitter.com/fyatlux";
export const openseaUrl = "https://opensea.io/FyatLux?tab=created";
export const instagramUrl = "https://www.instagram.com/fyatlux/";

const firebaseConfig = {
  apiKey: "AIzaSyClLddqz6PpQ0jXtppEcaFJtExC0BwRUeQ",
  authDomain: "fyatluxcycles.firebaseapp.com",
  projectId: "fyatluxcycles",
  storageBucket: "fyatluxcycles.appspot.com",
  messagingSenderId: "651719995568",
  appId: "1:651719995568:web:1038fbd87ddadafbf28b75",
  measurementId: "G-M71GYE6X41",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const isTestnet = false;
let initChainId = 0;

if (isTestnet) {
  initChainId = 4;
} else {
  initChainId = 1;
}

let expectedChainId = -1;
if (isTestnet) {
  expectedChainId = 4;
} else {
  expectedChainId = 1;
}

const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
  margin: 0px;
`;

const SModalTitle = styled.div`
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
`;

const SModalParagraph = styled.p`
  margin-top: 20px;
  max-width: 300px;
`;

const SModalSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  background-color: white;
`;

interface IAppState {
  fetching: boolean;
  address: string;
  web3: any;
  provider: any;
  connected: boolean;
  chainId: number;
  networkId: number;
  assets: IAssetData[];
  showModal: boolean;
  pendingRequest: boolean;
  result: any | null;
  currentState: ViewerState;
  tokenMetadata: any[];
  tokenIds: number[];
  worldTokenMetadata: any[];
  numWorldTokens: number[];
  activeTokenIndex: number;
  activeTokenIsWorld: boolean;
  glimpseId: number;
  votingPower: number;

  requestTitle: string;
  requestText: string;
  errorActive: boolean;
}

export enum ViewerState {
  CONNECT_WALLET,
  WRONG_NETWORK,
  ERROR,
}

const INITIAL_STATE: IAppState = {
  fetching: false,
  address: "",
  web3: null,
  provider: null,
  connected: false,
  chainId: initChainId,
  networkId: 1,
  assets: [],
  showModal: false,
  pendingRequest: false,
  result: null,
  currentState: ViewerState.CONNECT_WALLET,
  tokenMetadata: [],
  tokenIds: [],
  worldTokenMetadata: [],
  numWorldTokens: [],
  activeTokenIndex: -1,
  activeTokenIsWorld: false,
  glimpseId: -1,
  votingPower: -1,

  requestTitle: "",
  requestText: "",
  errorActive: false,
};

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });

  return web3;
}

class App extends React.Component<any, any> {
  // @ts-ignore
  public web3Modal: Web3Modal;
  public state: IAppState;

  constructor(props: any) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };

    this.web3Modal = new Web3Modal({
      disableInjectedProvider: true,
      network: this.getNetwork(),
      cacheProvider: false,
      providerOptions: this.getProviderOptions(),
    });
  }

  public componentDidMount() {
    if (this.web3Modal.cachedProvider) {
      this.onConnect();
    }
  }

  public onConnect = async () => {
    logEvent(analytics, "connect-start");
    // console.log("connecting");

    await this.web3Modal.clearCachedProvider();
    // console.log("clear cached providers")

    const provider = await this.web3Modal.connect();
    // console.log("connect")

    this.setState({
      requestTitle: "Connecting to Wallet",
      showModal: true,
      pendingRequest: true,
    });

    // console.log("set state")

    await this.subscribeProvider(provider);
    console.log("subscribed providers");

    const web3: any = initWeb3(provider);
    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const networkId = await web3.eth.net.getId();
    const chainId = await web3.eth.chainId();

    // console.log("chain id");

    if (expectedChainId !== chainId) {
      await this.setState({
        web3,
        provider,
        connected: true,
        address,
        chainId,
        networkId,
        showModal: true,
        currentState: ViewerState.WRONG_NETWORK,
      });
      logEvent(analytics, "mint-connect-wrong-network");
      this.showModal(
        "Wrong Network!",
        "You must be connected to the Ethereum Mainnet",
        true
      );
      return;
    } else {
      await this.setState({
        web3,
        provider,
        connected: true,
        address,
        chainId,
        networkId,
      });
    }

    // let's not do this until we actually need the data
    // await this.getAccountAssets();
    this.setState({
      requestTitle: "Retrieving Sparks...",
      showModal: true,
      pendingRequest: true,
    });

    const success = await this.retrieveTokens(address, chainId);

    this.setState({
      requestTitle: "",
      showModal: false,
      pendingRequest: false,
    });

    if (!success) {
      this.setState({
        showModal: true,
        currentState: ViewerState.ERROR,
        errorTitle: "Sorry, no Sparks or Fyat Lux tokens found in this wallet",
        errorText: "Please make sure you are connecting the correct wallet.",
      });
    }

    logEvent(analytics, "connect-finish");
  };

  public getVotingPower = async (sparks: number[], chainId: number) => {
    this.setState({ fetching: true });

    let votePower = null;

    try {
      // get account balances
      votePower = await apiGetVotingPower(sparks, chainId);
    } catch (error) {
      console.error(error); // tslint:disable-line
    }

    this.setState({ fetching: false });

    return votePower;
  };

  public subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }
    provider.on("disconnect", () => this.resetApp());

    provider.on("accountsChanged", async (accounts: string[]) => {
      if (accounts[0] === undefined) {
        this.resetApp();
      } else {
        await this.setState({ address: accounts[0] });
        await this.getAccountAssets();
      }
    });

    provider.on("chainChanged", async (chainId: number) => {
      const { web3 } = this.state;
      console.log("CHAIN has changed");
      const newChain = chainId + "";
      const newChainInt = parseInt(newChain, 16);
      console.log(newChainInt);

      let expectedChainId = -1;
      if (isTestnet) {
        expectedChainId = 4;
      } else {
        expectedChainId = 1;
      }
      const networkId = await web3.eth.net.getId();
      if (expectedChainId === newChainInt) {
        if (this.state.currentState === ViewerState.WRONG_NETWORK) {
          this.showModal(
            "Wrong Network!",
            "You must be connected to the Ethereum Mainnet",
            true
          );
        } else {
          await this.setState({ newChainInt, networkId });
        }
      } else {
        await this.setState({
          newChainInt,
          networkId,
          showModal: true,
          currentState: ViewerState.WRONG_NETWORK,
        });

        this.showModal(
          "Wrong Network!",
          "You must be connected to the Ethereum Mainnet",
          true
        );
      }
    });

    // provider.on("chainChanged", async (networkId: number) => {
    //   const { web3 } = this.state;
    //   const chainId = await web3.eth.chainId();

    //   await this.setState({ chainId, networkId });
    // });
  };

  public getNetwork = () => getChainData(this.state.chainId).network;

  public getProviderOptions = () => {
    const providerOptions = {
      "custom-metamask": {
        display: {
          logo: metamaskLogo,
          name: "MetaMask",
          description: "Connect with your MetaMask Wallet",
        },
        package: true,
        connector: async () => {
          if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
            window.location.assign(
              "https://metamask.app.link/dapp/www.ethbox.org/app/"
            );
            return;
          }

          let provider: any;
          if (typeof window.ethereum !== "undefined") {
            // @ts-ignore
            const providers: any = window.ethereum.providers;

            if (providers !== undefined) {
              console.log(providers);
              provider = providers.find((p: any) => p.isMetaMask); // <-- LOOK HERE
              try {
                await provider.request({ method: "eth_requestAccounts" });
              } catch (error) {
                throw new Error("User Rejected");
              }
            } else {
              provider = await detectEthereumProvider();
              try {
                await provider.request({ method: "eth_requestAccounts" });
              } catch (error) {
                throw new Error("User Rejected");
              }
            }
          } else {
            throw new Error("No MetaMask Wallet found");
          }

          return provider;
        },
      },
      "custom-coinbase": {
        display: {
          logo: coinbaseLogo,
          name: "Coinbase Wallet",
          description: "Connect with your Coinbase Wallet",
        },
        options: {
          appName: "Fyat Lux", // Your app name
          networkUrl: secret.alchemy,
          chainId: expectedChainId,
        },
        package: WalletLink,
        connector: async (_: any, options: any) => {
          const { appName, networkUrl, chainId } = options;
          const walletLink = new WalletLink({
            appName,
          });
          const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
          await provider.enable();
          return provider;
        },
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            1: secret.alchemy,
            4: secret.alchemyTest,
          },
        },
      },
      // torus: {
      //   package: Torus
      // },
      fortmatic: {
        package: Fortmatic,
        options: {
          key: { 1: "pk_live_62B7D2F197CDA179", 4: "pk_test_75979444702140C4" }[
            expectedChainId
          ],
        },
      },
    };
    return providerOptions;
  };

  public getAccountAssets = async () => {
    const { address, chainId } = this.state;
    this.setState({ fetching: true });
    try {
      // get account balances
      const assets = await apiGetAccountAssets(address, chainId);
      await this.setState({ fetching: false, assets });
    } catch (error) {
      console.error(error); // tslint:disable-line
      await this.setState({ fetching: false });
    }
  };

  public retrieveTokens = async (address: string, chainId: number) => {
    console.log("retrieving tokens");

    let setup = null;
    if (isTestnet) {
      setup = new Web3.providers.HttpProvider(secret.metadataTest);
    } else {
      setup = new Web3.providers.HttpProvider(secret.metadataMain);
    }
    const web3 = new Web3(setup);

    const fluxAutomata = getCollectibleContract(chainId, web3);
    const balanceOfAddress = await fluxAutomata.methods
      .balanceOf(address)
      .call();
    console.log(fluxAutomata);

    let token_metadata: any[] = [];
    let token_ids: any[] = [];

    for (let i = 0; i < balanceOfAddress; i++) {
      const tokenId = await fluxAutomata.methods
        .tokenOfOwnerByIndex(address, i)
        .call();
      console.log(tokenId);
      token_ids = token_ids.concat(tokenId);
      const token = await fluxAutomata.methods.tokenURI(tokenId).call();
      console.log(token);
      const metadata = await (await fetch(token)).json();
      console.log(metadata);
      token_metadata = token_metadata.concat(metadata);
    }

    const sparksFound = token_ids.length > 0;

    console.log("num sparks: ", token_ids.length);

    // Flux world tokens
    const fluxWorld = getFluxWorldContract(chainId, web3);
    const numEditions = 4;
    const balanceOfWorldAddress: number[] = [];
    const world_token_metadata: any[] = [];
    let tokensFound = false;

    for (let i = 0; i < numEditions; i++) {
      // editions start at 1, not 0
      const editionIndex = i + 1;
      balanceOfWorldAddress[i] = await fluxWorld.methods
        .balanceOf(address, editionIndex)
        .call();
      console.log(
        "Balance of edition(" +
          editionIndex +
          ") is: " +
          balanceOfWorldAddress[i]
      );

      if (balanceOfWorldAddress[i] > 0) {
        tokensFound = true;
      }
      const uri = await fluxWorld.methods.uri(editionIndex).call();

      world_token_metadata[i] = await (await fetch(uri)).json();
      console.log(world_token_metadata[i]);
    }

    console.log(fluxWorld);

    if (sparksFound || tokensFound) {
      // this.setState({
      //   requestTitle: "Calculating Flux power",
      //   showModal: true,
      //   pendingRequest: true,
      // });
      // const votePower = await this.getVotingPower(token_ids, chainId);

      // let voteNumber = -1;
      // if (votePower != null) {
      //   voteNumber = votePower.votingPower;
      // }

      await this.setState({
        tokenMetadata: token_metadata,
        tokenIds: token_ids,
        worldTokenMetadata: world_token_metadata,
        numWorldTokens: balanceOfWorldAddress,
        // votingPower: voteNumber,
      });
    } else {
      this.showModal(
        "Error",
        "There are no Sparks or Fyat Lux tokens in this wallet. Please ensure you are connected to the correct wallet",
        true
      );
    }

    return sparksFound || tokensFound;
  };

  public showModal = (title: string, description: string, error?: boolean) => {
    this.setState({
      showModal: true,
      errorActive: typeof error !== "undefined" ? true : false,
      requestTitle: title,
      requestText: description,
    });
  };

  public hideModal = () => {
    this.setState({
      showModal: false,
      errorActive: false,
      requestTitle: "",
      requestText: "",
    });
  };

  public selectToken = (tokenIndex: number, isWorldToken: boolean) => {
    this.setState({ activeTokenIndex: tokenIndex });
    this.setState({ activeTokenIsWorld: isWorldToken });
  };

  public exitDetails = () => {
    console.log("exit");
    this.setState({ activeTokenIndex: -1 });
    this.setState({ activeTokenIsWorld: false });
  };

  public logAnalytics = (eventName: string) => {
    logEvent(analytics, eventName);
  };

  public resetApp = async () => {
    const { web3 } = this.state;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }

    // this.web3Modal.cachedProvider = ""; // This is referring to your instance not the class
    localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    await this.web3Modal.clearCachedProvider();
    this.setState({ ...INITIAL_STATE });
  };

  // this is in the develop branch

  public render = () => {
    const {
      // assets,
      // address,
      connected,
      // chainId,
      showModal,
      // pendingRequest,
      // currentState,
      tokenMetadata,
      tokenIds,
      worldTokenMetadata,
      numWorldTokens,
      activeTokenIndex,
      activeTokenIsWorld,
      // glimpseId,
      requestTitle,
      requestText,
      errorActive,
    } = this.state;

    return (
      <BrowserRouter>
        <ScrollToTop>
          <div className={`${showModal ? "lock" : ""}`}>
            <Modal show={showModal} toggleModal={this.hideModal}>
              <SModalContainer>
                <SModalSection>
                  <SContainer>
                    <SModalTitle>{requestTitle}</SModalTitle>
                    {errorActive ? "" : <Loader />}
                    <SModalParagraph>{requestText}</SModalParagraph>
                    {errorActive ? (
                      <ErrorButton resetApp={this.resetApp} />
                    ) : (
                      ""
                    )}
                  </SContainer>
                </SModalSection>
              </SModalContainer>
            </Modal>
            <Routes>
              <Route
                path="/"
                element={
                  <Wrapper>
                    <MainPage
                      entrance={clip.enter}
                      connectWallet={this.onConnect}
                      killSession={this.resetApp}
                      connected={this.state.connected}
                      address={this.state.address}
                      logAnalytics={this.logAnalytics}
                    />
                  </Wrapper>
                }
              />
              <Route
                path="vault-of-the-ancients"
                element={
                  <Wrapper>
                    <Header
                      connectWallet={this.onConnect}
                      killSession={this.resetApp}
                      connected={this.state.connected}
                      address={this.state.address}
                      lightness={0.1}
                      opaque={true}
                    />
                    <VaultPage logAnalytics={this.logAnalytics} />
                  </Wrapper>
                }
              />
              <Route
                path="vota-phygitals"
                element={
                  <Wrapper>
                    <Header
                      connectWallet={this.onConnect}
                      killSession={this.resetApp}
                      connected={this.state.connected}
                      address={this.state.address}
                      lightness={0.1}
                      opaque={true}
                    />
                    <VaultStorePage
                      connectWallet={this.onConnect}
                      logAnalytics={this.logAnalytics}
                      numWorldTokens={numWorldTokens}
                    />
                  </Wrapper>
                }
              />
              <Route
                path="decide"
                element={
                  <Wrapper>
                    <Header
                      connectWallet={this.onConnect}
                      killSession={this.resetApp}
                      connected={this.state.connected}
                      address={this.state.address}
                      lightness={0.1}
                      opaque={true}
                    />
                    <VotePage
                      connectWallet={this.onConnect}
                      showModal={this.showModal}
                      hideModal={this.hideModal}
                      getVotingPower={this.getVotingPower}
                      web3={this.state.web3}
                      connected={this.state.connected}
                      address={this.state.address}
                      chainId={this.state.chainId}
                      votingPower={this.state.votingPower}
                      alreadyVoted={
                        this.state.votingPower === 0 &&
                        this.state.tokenIds.length > 0
                      }
                      // alreadyVoted={true}
                      logAnalytics={this.logAnalytics}
                    />
                  </Wrapper>
                }
              />
              <Route
                path="decideFinished"
                element={
                  <Wrapper>
                    <Header
                      connectWallet={this.onConnect}
                      killSession={this.resetApp}
                      connected={this.state.connected}
                      address={this.state.address}
                      lightness={0.1}
                      opaque={true}
                    />
                    <VoteFinished
                      disconnectWallet={this.resetApp}
                      logAnalytics={this.logAnalytics}
                    />
                  </Wrapper>
                }
              />
              <Route
                path="glimpses"
                element={
                  <Wrapper>
                    <Header
                      connectWallet={this.onConnect}
                      killSession={this.resetApp}
                      connected={this.state.connected}
                      address={this.state.address}
                      lightness={0.9}
                    />
                    <GlimpsesPage logAnalytics={this.logAnalytics} />
                  </Wrapper>
                }
              />
              <Route
                path="glimpse-details/:id"
                element={
                  <Wrapper>
                    <Header
                      connectWallet={this.onConnect}
                      killSession={this.resetApp}
                      connected={this.state.connected}
                      address={this.state.address}
                      lightness={0.1}
                      opaque={true}
                    />
                    <GlimpseDetailPage logAnalytics={this.logAnalytics} />
                  </Wrapper>
                }
              />
              <Route
                path="collection"
                element={
                  <Wrapper>
                    <Header
                      connectWallet={this.onConnect}
                      killSession={this.resetApp}
                      connected={this.state.connected}
                      address={this.state.address}
                      lightness={0.9}
                    />
                    <CollectionPage logAnalytics={this.logAnalytics} />
                  </Wrapper>
                }
              />
              <Route
                path="my-collection"
                element={
                  <Wrapper>
                    <Header
                      connectWallet={this.onConnect}
                      killSession={this.resetApp}
                      connected={connected}
                      address={this.state.address}
                      lightness={0.1}
                      opaque={true}
                    />
                    <MyCollectionPage
                      selectSparkFunction={this.selectToken}
                      exitDetailsFunction={this.exitDetails}
                      tokenMetadata={tokenMetadata}
                      tokenIds={tokenIds}
                      worldTokenMetadata={worldTokenMetadata}
                      numWorldTokens={numWorldTokens}
                      activeTokenIndex={activeTokenIndex}
                      activeTokenIsWorld={activeTokenIsWorld}
                      logAnalytics={this.logAnalytics}
                    />
                  </Wrapper>
                }
              />
              <Route
                path="privacy"
                element={
                  <Wrapper>
                    <Header
                      connectWallet={this.onConnect}
                      killSession={this.resetApp}
                      connected={this.state.connected}
                      address={this.state.address}
                      lightness={0.1}
                      opaque={true}
                    />
                    <Privacy logAnalytics={this.logAnalytics} />
                  </Wrapper>
                }
              />
              <Route
                path="terms-of-service"
                element={
                  <Wrapper>
                    <Header
                      connectWallet={this.onConnect}
                      killSession={this.resetApp}
                      connected={this.state.connected}
                      address={this.state.address}
                      lightness={0.1}
                      opaque={true}
                    />
                    <TermsOfService logAnalytics={this.logAnalytics} />
                  </Wrapper>
                }
              />
              <Route
                path="marketing-policy"
                element={
                  <Wrapper>
                    <Header
                      connectWallet={this.onConnect}
                      killSession={this.resetApp}
                      connected={this.state.connected}
                      address={this.state.address}
                      lightness={0.1}
                      opaque={true}
                    />
                    <MarketingPolicy logAnalytics={this.logAnalytics} />
                  </Wrapper>
                }
              />
              <Route
                path="digital-collectible-terms"
                element={
                  <Wrapper>
                    <Header
                      connectWallet={this.onConnect}
                      killSession={this.resetApp}
                      connected={this.state.connected}
                      address={this.state.address}
                      lightness={0.1}
                      opaque={true}
                    />
                    <DigitalCollectibleTerms logAnalytics={this.logAnalytics} />
                  </Wrapper>
                }
              />
            </Routes>
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  };
}

function ErrorButton(props: { resetApp: () => void }) {
  const navigate = useNavigate();

  return (
    <div className="error-button">
      <button
        onClick={() => {
          navigate("/");
          props.resetApp();
        }}>
        Continue
      </button>
    </div>
  );
}
export default App;
