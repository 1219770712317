import React from 'react'
import { useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";

import './GlimpseDetailPage.scss';

import glimpseData from "./glimpseData.json"

function getTitle(id: number)
{
    return glimpseData[id].title;
}

function getImage(id: number)
{
    const glimpseNumber = id + 1
    return "../glimpses" + glimpseNumber.toString() + ".png";
}

export default function GlimpseDetailPage(props: { logAnalytics: (eventName: string) => void }) {
    const { id } = useParams();
    const glimpseId = Number(id!);

    const location = useLocation();

    useEffect(() => {
        console.log("glimpse detail page");
        props.logAnalytics("glimpse detail page");
    }, [location]);

    return (
        <div className='glimpse-detail-page'>
            <div className='glimpse-detail-fg'>
                <div className='text'>
                    <h1>{ getTitle(glimpseId) }</h1>
                    <img className='glimpse-image' src={getImage(glimpseId) } />
                    <div dangerouslySetInnerHTML={{__html:glimpseData[glimpseId].text}}/>
                </div>
            </div>
        </div>
    )
}