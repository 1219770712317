import React from 'react'
import ReactDOM from 'react-dom/client';

import App from './App'
import reportWebVitals from './report-web-vitals';

// @ts-ignore
declare global {
    // tslint:disable-next-line
    interface Window {
      web3: any;
      // @ts-ignore
      ethereum: any;
      Web3Modal: any;
      Box: any;
      box: any;
      space: any;
    }
}

const root = ReactDOM.createRoot(
    document.getElementById('app') as HTMLElement
);

root.render(<App />);

reportWebVitals();