import * as React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const SContainer = styled.div`
  width: 100vw;
  min-width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
  align-self: center;
`;

const STermsContainer = styled.div`
  height: 100%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  background-color: #f2eeec;
  justify-content: flex-start;
  padding: 100px 48px 32px;
`;

const SHeaderText = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
`;

const SMainText = styled.h4`
  max-width: 70vw;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  align-self: center;
  text-align: left;
`;

const MarketingPolicy = (props: {
  logAnalytics: (eventName: string) => void;
}) => {
  const location = useLocation();

  useEffect(() => {
    console.log("marketing policy page");
    props.logAnalytics("marketing policy page");
  }, [location]);

  return (
    <SContainer>
      <STermsContainer>
        <SHeaderText>FYAT LUX MARKETING POLICY</SHeaderText>
        <SMainText>
          <b>Last Updated: 26 December 2021</b>
          <br />
          <br />
          <br />
          Fyat Lux combines groundbreaking technologies with mind-blowing art to
          launch the next evolution of NFTs for world-building and storytelling.
          Before delving into the Fyat Lux universe, please read this Marketing
          Policy (“Marketing Policy”) carefully.
          <br />
          <br />
          Fyat Lux is committed to the responsible marketing of its products.
          This policy describes the required marketing practices for holders of
          any blockchain-based, cryptographic nonfungible token (“NFT”) which,
          together with the license rights to any associated digital works of
          authorship or other content, whether or not copyrighted or
          copyrightable, and regardless of the format in which any of the
          foregoing is made available, comprises and is referred to herein as a
          “Digital Collectible”. By purchasing a Digital Collectible, you agree
          that you will comply with this Marketing Policy in connection with
          holding and transferring the Digital Collectible.
          <br />
          <br />
          1. Overall Focus of Marketing. In general, Digital Collectibles should
          be marketed in a manner that emphasizes the consumptive value or
          functionality of the Digital Collectible.
          <br />
          <br />
          2. Prohibited Marketing Activity. The following marketing activities
          are prohibited when marketing any Digital Collectible. Marketing will
          not: Include statements that could cause potential purchasers of a
          Digital Collectible to have a reasonable expectation of profits,
          including but not limited to: References to opportunities for price
          appreciation, resale value or secondary market opportunities; and
          Language typically associated with investment returns, such as
          mentions of a Digital Collectible’s RoI, RoR, or market
          capitalization. Emphasize or discuss the rarity of a Digital
          Collectible unless the rarity is expressly associated with the Digital
          Collectible’s unique features, designs, or characteristics and it is
          made clear that the rarity is not intended to suggest the likelihood
          of price appreciation or future resale value. Emphasize the
          availability of third-party exchanges or marketplaces for trading a
          Digital Collectible. For clarity, mentions of transferability of the
          Digital Collectible to metaverses, videogames, and other platforms
          that currently support consumptive use of the Digital Collectible are
          allowed. Contain statements promising future functionality that will
          be associated with any Digital Collectible. This includes but is not
          limited to statements regarding future utility, off-chain benefits, or
          technological developments. Contain any untruthful, incomplete,
          unsupported, inaccurate or misleading statements.
          <br />
          <br />
          3. Marketing Physical Collectibles. Physical collectibles should be
          marketed according to the principles listed here. When reselling a
          Digital Collectible that is packaged with a physical collectible,
          marketing should emphasize the connection between the Digital
          Collectible and its associated physical collectibles, if applicable.
        </SMainText>
      </STermsContainer>
    </SContainer>
  );
};

export default MarketingPolicy;
