import * as React from "react";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import mask_image from "../assets/Duality_01a.png";
import app_image from "../assets/AndroidLogo.png";
import model_image from "../assets/model-3d.png";
import tposed_image from "../assets/t-posed.png";
import { saveAs } from "file-saver";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useState } from "react";

import "../theme.scss";

const STopContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2eeec;
`;

const SMainContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 100px;
`;

const SViewerContainer = styled.div`
  height: 85vh;
  width: 60vw;
  min-width: 500px;
  max-width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;
  align-self: flex-start;
  margin: 25px 75px;
  //flex: 2 1;

  @media (min-width: 900px) {
    position: sticky;
    top: 150px;
  }

  @media (max-width: 720px) {
    min-width: 85vw;
  }
`;

const SContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  max-width: 30vw;
`;

const SViewerButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const SSparkContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  align-self: flex-start;
  margin: 10px 0px;
  margin-bottom: 20px;
`;

const SSparkContainerApp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;
  align-self: flex-start;
  margin: 10px 0px;
  margin-bottom: 20px;
  align-items: center;
  text-align: left;
`;

const SSparkContainerPfp = styled.div`
  //height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;
  align-self: flex-start;
  margin: 10px 0px;
  margin-bottom: 20px;
`;

const SInfoText = styled.h3`
  font-family: "Lexend";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: rgb(30, 30, 30);
  text-align: center;
  margin-bottom: 0;
  text-transform: uppercase;
`;

const STokenIDText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 1;
  color: rgb(30, 30, 30);
  text-align: left;
  padding-bottom: 20px;
`;

const SInstructionText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgb(30, 30, 30);
  text-align: center;
`;

const SAppText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgb(30, 30, 30);
  text-align: left;
  padding-left: 10px;
  overflow-wrap: normal;
`;

const SAppVersionText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: rgb(30, 30, 30);
  text-align: left;
  padding-left: 10px;
  padding-bottom: 10px;
  overflow-wrap: normal;
`;

const SInstructionList = styled.ol`
  margin: 0;
  padding-bottom: 10px;
  font-size: 14px;
`;

const SMainInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SMainInfoText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 38px;
  color: rgb(30, 30, 30);
  text-align: left;
  padding-right: 20px;
`;

const SExternalInfo = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;

  a {
    color: #303030;
    text-decoration: none;
    padding-right: 20px;
  }
`;

const STraitTopContainer = styled.div`
  //width: 45vw;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 0px;
`;

const STraitContainer = styled.div`
  max-height: 100%;
  padding: 10px 0 10px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

const STrait = styled.div`
  min-width: 205px;
  background-color: rgb(140, 143, 145);
  color: white;
  border-radius: 4px;
  border: 2px solid color rgb(0, 0, 0);
  padding: 8px;
  margin: 8px 5px;
`;

const STraitType = styled.div`
  color: rgb(230, 230, 230);
  font-size: 16px;
`;

const STraitValue = styled.div`
  color: rgb(30, 30, 30);
  font-size: 20px;
`;

const SDownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const SSectionTitle = styled.span`
  color: rgb(30, 30, 30);
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
  padding: 50px 0 10px 0;
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 26px;
    line-height: 1;
  }
`;

const SDownloadImage = styled.img`
  width: 15vw;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 4px;

  @media (max-width: 720px) {
    width: 40vw;
  }
`;

const SReturnButton = styled.div`
  padding: 0;
  font-size: 3vw;
  margin: 0 10px 20px;
  align-self: flex-start;
  z-index: 100;
  color: rgb(30, 30, 30);
  cursor: pointer;
`;

const SDownloadLink = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #b1b1b1;
  cursor: pointer;
  text-align: center;
`;

const SIFrame = styled.iframe`
  z-index: 100;
  border-radius: 4px;
  max-width: 80vw;
  padding: 20px;
`;

const saveFile = (link: string, fileName: string) => {
  saveAs(link, fileName);
};

interface IOverviewProps {
  metadata: any;
  returnToSelectorFunc: () => void;
  tokenId: number;
}

const linkReplace = (text: string) => {
  return text.replace("ipfs://", "https://matiria.mypinata.cloud/ipfs/");
};

const SparkDetails = (props: IOverviewProps) => {
  const { metadata, returnToSelectorFunc, tokenId } = props;

  console.log(metadata);
  console.log(tokenId);
  const [FaceUrl, setFaceUrl] = useState("");
  const [LensUrl, setLensUrl] = useState("");
  const [AppUrl, setAppUrl] = useState("");

  const storage = getStorage();
  const gsReference = ref(
    storage,
    "gs://fyatluxtest.appspot.com/faces_glb/" + tokenId + ".glb"
  );
  const lensReference = ref(
    storage,
    "gs://fyatluxtest.appspot.com/LensStudio/FyatLuxDuality.zip"
  );
  const appReference = ref(
    storage,
    "gs://fyatluxtest.appspot.com/android/FyatLuxAndroidApp_10038.apk"
  );

  getDownloadURL(gsReference).then((faceUrl) => {
    setFaceUrl(faceUrl);
  });

  getDownloadURL(lensReference).then((lensUrl) => {
    setLensUrl(lensUrl);
  });

  getDownloadURL(appReference).then((appUrl) => {
    setAppUrl(appUrl);
  });

  return (
    <>
      <STopContainer>
        <SMainContainer>
          <SViewerContainer>
            <SReturnButton onClick={returnToSelectorFunc}>
              RETURN TO COLLECTION
            </SReturnButton>

            <SIFrame
              src={"https://fyatlux-viewer.web.app/?tokenID=" + tokenId}
              width="100%"
              height="100%"
              frameBorder="0">
              Browers not compatible
            </SIFrame>
          </SViewerContainer>
          <SContentContainer>
            <SMainInfoContainer>
              <STokenIDText>Spark #{tokenId}</STokenIDText>
              <SMainInfoText>Fyat Lux</SMainInfoText>
              <SExternalInfo>
                <a
                  href={
                    "https://opensea.io/assets/ethereum/0x14c4471a7f6dcac4f03a81ded6253eaceff15b3d/" +
                    tokenId
                  }>
                  VIEW ON OPENSEA
                </a>
                <a
                  href={
                    "https://etherscan.io/nft/0x14C4471A7F6dCAc4F03a81Ded6253eacEfF15B3d/" +
                    tokenId
                  }>
                  VIEW ON ETHERSCAN
                </a>
              </SExternalInfo>
            </SMainInfoContainer>
            <SSectionTitle>Traits</SSectionTitle>
            <STraitTopContainer>
              <STraitContainer>
                {metadata.attributes.map((d: any, i: number) => (
                  <STrait key={`${i}`}>
                    <STraitType>{d.trait_type}</STraitType>
                    <STraitValue>{d.value}</STraitValue>
                  </STrait>
                ))}
              </STraitContainer>
            </STraitTopContainer>

            <SSectionTitle>2D DOWNLOADS</SSectionTitle>
            <SDownloadContainer>
              <SSparkContainerImage>
                <SDownloadImage
                  src={linkReplace(metadata.image)}
                  alt={"image of " + metadata.name}
                />
                <SInfoText>Card Image</SInfoText>
                <SDownloadLink
                  onClick={() =>
                    saveFile(
                      linkReplace(metadata.image),
                      "Spark-Card-" + tokenId + ".jpg"
                    )
                  }>
                  Download Card Image
                </SDownloadLink>
              </SSparkContainerImage>

              <SSparkContainerPfp>
                <SDownloadImage
                  src={linkReplace(metadata.pfp)}
                  alt={"image of " + metadata.name}
                />
                <SInfoText>Profile Picture</SInfoText>
                <SDownloadLink
                  onClick={() =>
                    saveFile(
                      linkReplace(metadata.pfp),
                      "Spark-Profile-" + tokenId + ".jpg"
                    )
                  }>
                  Download Profile Picture
                </SDownloadLink>
              </SSparkContainerPfp>
            </SDownloadContainer>

            <SSectionTitle>3D DOWNLOADS</SSectionTitle>
            <SDownloadContainer>
              <SSparkContainerImage>
                <SDownloadImage src={model_image} alt={"image of 3d model"} />
                <SInfoText>3D Model</SInfoText>
                <SDownloadLink
                  onClick={() =>
                    saveFile(
                      linkReplace(metadata.model),
                      "Spark-" + tokenId + ".glb"
                    )
                  }>
                  Download 3D Model
                </SDownloadLink>
              </SSparkContainerImage>

              <SSparkContainerImage>
                <SDownloadImage src={tposed_image} alt={"image of 3d model"} />
                <SInfoText>T-Posed 3D Model</SInfoText>
                <SDownloadLink
                  onClick={() =>
                    saveFile(
                      linkReplace(metadata.tpose),
                      "Spark-TPose-" + tokenId + ".glb"
                    )
                  }>
                  Download T-posed 3D Model
                </SDownloadLink>
              </SSparkContainerImage>
            </SDownloadContainer>

            <SSectionTitle>MISC DOWNLOADS</SSectionTitle>
            <SDownloadContainer>
              <SSparkContainerImage>
                <SDownloadImage
                  src={mask_image}
                  alt={"image of person wearing Fyat Lux mask"}
                />
                <SInfoText>Duality Mask</SInfoText>
                <SInstructionText>Instructions:</SInstructionText>
                <SInstructionList>
                  <li>
                    <SDownloadLink>
                      <a href="https://lensstudio.snapchat.com/">
                        Download Lens Studio
                      </a>
                    </SDownloadLink>
                  </li>
                  <li>
                    <SInstructionText>
                      Open Fyat Lux Lens Project
                    </SInstructionText>
                  </li>
                  <li>
                    <SInstructionText>
                      Drag mask file onto template
                    </SInstructionText>
                  </li>
                </SInstructionList>
                <SViewerButtonContainer>
                  <SDownloadLink
                    onClick={() =>
                      saveFile(LensUrl, "FyatLux_Mask_LensStudio.zip")
                    }>
                    Download Lens Project
                  </SDownloadLink>
                  <SDownloadLink
                    onClick={() =>
                      saveFile(FaceUrl, "SparkMask-" + tokenId + ".glb")
                    }>
                    Download Mask {tokenId}
                  </SDownloadLink>
                </SViewerButtonContainer>
              </SSparkContainerImage>

              <SSparkContainerApp>
                <SDownloadImage src={app_image} alt={"image of android app"} />
                <SInfoText>Android App</SInfoText>
                <SAppText>If you do not have access to</SAppText>
                <SAppText>the Google Play Store, you</SAppText>
                <SAppText>can download the Android App here</SAppText>
                <SAppVersionText>Version 1.03 (10038)</SAppVersionText>
                <SViewerButtonContainer>
                  <SDownloadLink
                    onClick={() => saveFile(AppUrl, "FyatLuxAndroidApp.apk")}>
                    Download Android App
                  </SDownloadLink>
                </SViewerButtonContainer>
              </SSparkContainerApp>
            </SDownloadContainer>
          </SContentContainer>
        </SMainContainer>
      </STopContainer>
    </>
  );
};

SparkDetails.propTypes = {
  wallet_id: PropTypes.string,
};

export default SparkDetails;
