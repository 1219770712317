import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { apiGetVotingPower } from "../helpers/api";
import { discordUrl } from "../App";

import "./VotePage.scss";

interface IVoteProps {
  connectWallet: () => void;
  showModal: (title: string, description: string) => void;
  hideModal: () => void;
  getVotingPower: (sparks: number[], chainId: number) => void;
  web3: any;
  connected?: boolean;
  address: string;
  chainId: number;
  votingPower: any;
  alreadyVoted: boolean;
  logAnalytics: (eventName: string) => void;
}

export default function VotePage(props: IVoteProps) {
  const { chainId } = props;

  const [text, setText] = useState("");
  const [submitted, setSubmitted] = useState("");
  const [power, setPower] = useState("");
  const [showFluxResult, setShowFluxResult] = useState(false);

  const location = useLocation();
  console.log(submitted);

  useEffect(() => {
    props.logAnalytics("vote page");
  }, [location]);

  function handleChange(e: any) {
    console.log("change", e.target.value);
    setText(e.target.value);
    setShowFluxResult(false);
  }

  function handleSubmit(e: any) {
    console.log("submit", e.target.value);
    e.preventDefault();
    setSubmitted(text);

    console.log("pre get power");

    getFluxPower(text, chainId);

    console.log("post get power");
  }

  async function getFluxPower(text: string, chainId: number) {
    const id_number = parseInt(text, 10);

    if (id_number === 0 || id_number > 8080) {
      setPower("-1");
      setShowFluxResult(true);
    } else {
      const votePower = await apiGetVotingPower([id_number], chainId);
      setPower(votePower.votingPower);
      console.log("getting flux power", votePower);
      setShowFluxResult(true);
    }
  }

  return (
    <div className="vote-page">
      <div className="top-section">
        <div className="vote-section">
          <div className="info-section">
            <div className="vote-title">
              <h1>The decision is complete...</h1>
            </div>
            <div className="vote-text">
              <p>
                From July 21 to August 3, 2022,
                <a href="https://opensea.io/collection/fyat-lux-dawn">
                  {" Fyat Lux NFT "}
                </a>{" "}
                owners had the chance to participate in the first community
                decision for Fyat Lux and decide the finale of our first
                chapter, Vault of the Ancients.
              </p>
              <p>
                Our two main characters - Null and Ninez - were at a critical
                moment in their quest to unlock a new future for all Sparks.
                With Ninez badly damaged and a deadly sentry standing in Null’s
                way, the Community had to make a choice - sacrifice Ninez but
                succeed in the mission or sacrifice the mission but save Ninez.
              </p>
              <p>
                The Community’s decision was extremely close! Check back on
                August 16, 2022 to read the finale and see what they decided! In
                the meantime,
                <a href={discordUrl}>{" join our community on Discord "}</a> to
                discuss all things Fyat Lux!
              </p>
            </div>
            <div className="flux-checker">
              <form onSubmit={handleSubmit}>
                <span>
                  Curious about a Spark’s Flux Power?
                  <br />
                  Enter a Spark ID to check!
                </span>
                <br />
                <input
                  type="text"
                  onChange={handleChange}
                  onKeyPress={(event) => {
                    if (event.key !== "Enter") {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }
                  }}
                />
                <input type="submit" value="check" />
                <br />
                {showFluxResult ? (
                  <FluxPowerResult power={parseInt(power, 10)} sparkId={text} />
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
          <UnconnectedOptions />
        </div>
      </div>
    </div>
  );
}

function FluxPowerResult(props: { power: number; sparkId: string }) {
  return (
    <div className="flux-power-result">
      {props.power === -1 ? (
        <span>Please enter a valid 4-digit Spark ID</span>
      ) : props.power === 0 ? (
        <div>
          <span>
            Spark # {props.sparkId} has already decided. Its Flux Power is
            depleted.
          </span>
        </div>
      ) : (
        <span>
          {" "}
          Spark # {props.sparkId} has {props.power} Flux remaining!{" "}
        </span>
      )}
    </div>
  );
}

function UnconnectedOptions() {
  return (
    <div className="vote-option-section">
      <div className="vote-option">
        <img className="vote-img" src="/ChoiceA_Small_Blurred.jpg" />
      </div>
    </div>
  );
}
