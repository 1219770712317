import * as React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const SContainer = styled.div`
  width: 100vw;
  min-width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
  align-self: center;
`;

const STermsContainer = styled.div`
  height: 100%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  background-color: #f2eeec;
  justify-content: flex-start;
  padding: 100px 48px 32px;
`;

const SHeaderText = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
`;

const SMainText = styled.h4`
  max-width: 70vw;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  align-self: center;
  text-align: left;
`;

const SPageLink = styled.h1`
  max-width: 70vw;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  align-self: center;
  text-align: left;
`;

const DigitalCollectibleTerms = (props: {
  logAnalytics: (eventName: string) => void;
}) => {
  const location = useLocation();

  useEffect(() => {
    console.log("marketing policy page");
    props.logAnalytics("DigitalCollectibleTerms page");
  }, [location]);

  return (
    <SContainer>
      <STermsContainer>
        <SHeaderText>FYAT LUX DIGITAL COLLECTIBLE TERMS</SHeaderText>
        <SPageLink>
          <a href="#dawn-collection-digital-collectible-terms">
            Fyat Lux Dawn Collection Digital Collectible Terms
          </a>
        </SPageLink>
        <SPageLink>
          <a href="#world-collection-digital-collectible-terms">
            Fyat Lux World Collection Digital Collectible Terms
          </a>
        </SPageLink>
        <SMainText>
          <br id="dawn-collection-digital-collectible-terms" />
          <br />
          <br />
          <h1>FYAT LUX DAWN COLLECTION DIGITAL COLLECTIBLE TERMS</h1>
          <br />
          <br />
          <b>Last Updated: 28 December 2023</b>
          <br />
          <br />
          <br />
          By acquiring lawful ownership to a non-fungible token (“NFT”)
          associated with the Fyat Lux Dawn Collection made available under this
          NFT License (“Dawn Collection NFT”) created by the owner or entity
          that has created this Dawn Collection NFT (“Creator” or “we” or
          “our”), you agree to these Digital Collectible License Terms and
          Conditions (the “Terms”). When you lawfully own a Dawn Collection NFT,
          you own all personal property rights to the NFT underlying the Dawn
          Collection NFT (e.g., the right to freely sell, transfer, or otherwise
          dispose of that Dawn Collection NFT). However, your rights to the
          associated artwork, images, video, content or other works of
          authorship linked to your specific Dawn Collection NFT which was
          developed by Creator (“NFT Media”) are only as outlined below.
          <br />
          <br />
          1. NFT LICENSE GRANT
          <br />
          <br />
          1.1 NFT Media License. For as long as you lawfully own a Dawn
          Collection NFT, Creator grants you a non-exclusive, perpetual,
          worldwide license under our copyrights to use, distribute, reproduce,
          display, perform, modify, and create derivative works of the specific
          NFT Media linked to your Dawn Collection NFT for personal and
          commercial uses, with the right to sublicense such rights through
          multiple tiers of sublicensees subject to the limitations in Section
          1.4, and Articles 2 and 3 of these Terms with Creator being a third
          party beneficiary to all such sublicenses with the ability to enforce
          such agreements. This license includes the right to display as a
          profile picture, display on products or services using the NFT Media
          or NFT Media Derivatives (as defined below), display on sold
          merchandise, use in your original content, or to display in a physical
          or digital museum. All intellectual property rights in and to the NFT
          Media and any other intellectual property rights of Creator not
          expressly licensed herein are reserved by Creator.
          <br />
          <br />
          1.2 Modifications and Derivative Works. We understand that you may
          want to create derivative works of the NFT Media (“Your NFT Media
          Derivatives”) and we allow you to do so under the scope of the license
          granted above. However, you acknowledge and agree that (a) we may also
          create our own future derivatives of the NFT Media, (b) the subsequent
          lawful owner of the Dawn Collection NFT may create its own derivatives
          of the NFT Media and (c) other owners of their own Dawn Collection
          NFTs and the associated artwork, images, video, content or other works
          of authorship linked to such Dawn Collection NFT (“Other Dawn
          Collection NFT Media”) may also create their own derivatives of the
          Other Dawn Collection NFT Media (each of them “Other NFT Media
          Derivatives”).
          <br />
          <br />
          These Other NFT Media Derivatives may be similar or identical to Your
          NFT Media Derivatives. Accordingly, on behalf of yourself and your
          heirs, successors and assigns, you irrevocably covenant and agree not
          to assert or bring any suit, claim, demand or challenge against (a)
          Creator or its past, present and future parents, affiliates or
          licensees (or any of their partners, members, employees, officers,
          directors, contractors, agents and equityholders) in connection with
          their use distribution, reproduction, display, perform, modification,
          and creation of derivative works of any NFT Media or any of their own
          Other NFT Media Derivatives or (b) any other Dawn Collection NFT owner
          or its past, present and future parents, affiliates or licensees (or
          any of their partners, members, employees, officers, directors,
          contractors, agents and equityholders) in connection with the use
          distribution, reproduction, display, perform, modification, and
          creation of derivative works of the Other Dawn Collection NFT Media or
          any of their Other NFT Media Derivatives. The foregoing is the case
          even if such Other NFT Media or Other NFT Media Derivatives is similar
          to or the same as any of Your NFT Media Derivatives that have been
          created by you.
          <br />
          <br />
          1.3 No Rights to Trademarks. Nothing in these Terms is meant to grant
          you any rights to any logos, trademarks, service marks, and trade
          dress associated with Creator or the Dawn Collection NFTs (“Project
          Trademarks”). Unless you have our prior written approval, you may not
          use any Project Trademarks for any use that would require a license
          from us, including to register any domain names or social media
          accounts using any Project Trademarks, in any NFT Media Derivatives,
          or to advertise or promote any other products or services.
          <br />
          <br />
          1.4 Transfer and Sublicensing. The licenses granted in these Terms are
          non-transferrable, except that if you lawfully transfer ownership of
          your Dawn Collection NFT, the license to the NFT Media in Section 1.1
          to you shall terminate upon the effective date of such transfer, and
          such licenses will be assigned to the new owner of the Dawn Collection
          NFT associated with such NFT Media. As a condition to sales, transfers
          or similar transactions of the Dawn Collection NFTs, the transferee
          agrees upon the acquisition of the Dawn Collection NFT that (a) the
          transferee is not a Restricted Party and (b) the transferee accepts
          these Terms. Further, if you choose to sublicense any of your licensed
          rights set forth in Section 1.1 above, you are only permitted to do so
          if any such sublicensees agree (i) that they are not Restricted
          Parties, (ii) to the same covenant not to assert as set forth in the
          second to last sentence of Section 1.2, and (iii) that if your
          licensed rights in Section 1.1 are transferred (such as because you
          sell your Dawn Collection NFT), then any such sublicenses you have
          granted in such licensed rights will automatically terminate. Because
          virtually all public blockchains are licensed under open source
          licenses, it is possible that the blockchain may fork, merge, or
          duplicate the original blockchain that initially recorded ownership of
          your Dawn Collection NFT. In such case, any rights granted under these
          Terms to owners of any Dawn Collection NFT will only be granted to the
          lawful owners of such Dawn Collection NFT whose ownership is recorded
          on the mainnet version of the blockchain that is generally recognized
          and predominantly supported in the blockchain industry as the
          legitimate successor of the original blockchain (as determined in our
          sole discretion).
          <br />
          <br />
          1.5 Third Party Content. The Creator hereby represents and warrants to
          you that all of the copyrights in the NFT Media are owned by the
          Creator, and does not contain (a) any artwork, images, video, content
          or other works of authorship, (b) logos, trademarks, service marks, or
          trade dress or rights of personality in which the relevant
          intellectual property rights are not owned by the Creator (“Third
          Party Content”), provided that the foregoing shall not apply if the
          Creator has obtained a license to such Third Party Content consistent
          with the licenses under this Agreement or the Creator has supplemented
          this NFT License with an additional license that governs your right to
          use such Third Party Content.
          <br />
          <br />
          1.6 Restrictions. Notwithstanding any of the above, you may not use
          the NFT Media in any way that constitutes unlawful, defamatory,
          harassing, abusive, fraudulent, racist, hateful, vulgar, cruel,
          illegal or obscene, or that promotes any such activity, as determined
          in Creator’s sole discretion, it being understood that Creator may
          designate another entity such as a decentralized autonomous
          organization (“DAO”) or committee of a DAO to make this determination
          in Creator’s place, in which case Creator will be bound by that other
          entity’s decision. If a Dawn Collection NFT is fractionalized into
          smaller ownership interests (which may be represented by other
          tokens), the rights licensed hereunder do not transfer to each of the
          owners of such fractionalized interests in the Dawn Collection NFT,
          but are only granted to those who own all fractionalized interests in
          a Dawn Collection NFT or as may otherwise be agreed by the owners of
          such fractionalized interests if each of such owners agree that (a)
          the owner is not a Restricted Party and (b) the owner accepts these
          Terms. In order to purchase the Dawn Collection NFT if you are an
          individual, you must be 18 years of age or older if the age of
          lawfully capacity of forming binding contracts is older in the
          relevant jurisdiction. If you are an entity, the individual agreeing
          to the Terms must have the legal authority to bind the entity. If (a)
          you are an individual, you agree on your own behalf and (b) if you are
          an entity, you agree that neither the entity nor any of your owners or
          investors or any of their directors, officers, employees, agents or
          affiliates acting on your behalf: (i) is related in any way to, the
          governments of, or any persons within, any country or jurisdiction
          under a U.S. embargo enforced by the Office of Foreign Assets Control
          (“OFAC”), or any persons who are named on any list of sanctioned
          individuals or entities; (ii) is (or has ever been) prohibited from
          the transaction pursuant to U.S. anti-money laundering,
          anti-terrorist, economic sanctions and asset control laws; and (iii)
          is resident in a country or jurisdiction under a U.S. embargo enforced
          by OFAC (“Restricted Parties”).
          <br />
          <br />
          2. DISCLAIMERS, LIMITATIONS OF LIABILITY, AND INDEMNIFICATION
          <br />
          <br />
          2.1 Disclaimers. YOUR ACCESS TO AND USE OF THE DAWN COLLECTION NFT AND
          NFT MEDIA IS AT YOUR OWN RISK. EXCEPT AS EXPRESSLY SET FORTH HEREIN,
          TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, CREATOR, ITS
          PARENTS, AFFILIATES, PARTNERS, EMPLOYEES, OFFICERS, DIRECTORS,
          CONTRACTORS, AGENTS, LICENSORS AND EQUITYHOLDERS (THE “CREATOR
          ENTITIES”) DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR
          IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT IN THE
          DAWN COLLECTION NFTS AND NFT MEDIA. THE CREATOR ENTITIES MAKE NO
          WARRANTY OR REPRESENTATION AND DISCLAIM ALL RESPONSIBILITY AND
          LIABILITY FOR: (A) THE COMPLETENESS, ACCURACY, AVAILABILITY,
          TIMELINESS, ORIGINALITY, SECURITY OR RELIABILITY OF THE DAWN
          COLLECTION NFTS AND NFT MEDIA; (B) THE OPERATION OR COMPATIBILITY WITH
          ANY OTHER APPLICATION OR ANY PARTICULAR SYSTEM, DEVICE, BLOCKCHAIN,
          DIGITAL WALLET, HARDWARE OR MARKETPLACE; AND (C) WHETHER THE DAWN
          COLLECTION NFTS AND NFT MEDIA WILL MEET YOUR REQUIREMENTS OR BE
          AVAILABLE ON AN UNINTERRUPTED, SECURE OR ERROR-FREE BASIS; AND (D) THE
          DELETION OF, OR THE FAILURE TO STORE OR TRANSMIT THE DAWN COLLECTION
          NFTS AND NFT MEDIA. THE DAWN COLLECTION NFTS AND NFT MEDIA ARE
          INTENDED FOR CONSUMER ENJOYMENT, USE AND CONSUMPTION ONLY.
          <br />
          <br />
          2.2 Limitations of Liability. TO THE EXTENT NOT PROHIBITED BY LAW, YOU
          AGREE THAT IN NO EVENT WILL THE CREATOR ENTITIES BE LIABLE (A) FOR ANY
          INDIRECT, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
          DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
          GOODS OR SERVICES, LOSS OF USE, DATA OR PROFITS, BUSINESS INTERRUPTION
          OR ANY OTHER DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE
          OR INABILITY TO USE THE DAWN COLLECTION NFT OR THE NFT MEDIA), HOWEVER
          CAUSED AND UNDER ANY THEORY OF LIABILITY, WHETHER UNDER THESE TERMS OR
          OTHERWISE ARISING IN ANY WAY IN CONNECTION WITH THE DAWN COLLECTION
          NFTS AND NFT MEDIA OR THESE TERMS AND WHETHER IN CONTRACT, PRODUCT
          LIABILITY OR TORT (INCLUDING STRICT LIABILITY OR NEGLIGENCE) OR
          OTHERWISE, EVEN IF THE CREATOR ENTITIES HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGE, OR (B) FOR ANY OTHER CLAIM, DEMAND OR
          DAMAGES WHATSOEVER RESULTING FROM OR ARISING OUT OF OR IN CONNECTION
          WITH THESE TERMS OR THE DELIVERY, USE OR PERFORMANCE OF THE DAWN
          COLLECTION NFTS AND NFT MEDIA. THE MAXIMUM AGGREGATE LIABILITY OF THE
          CREATOR ENTITIES FOR ALL DAMAGES AND CAUSES OF ACTION, WHETHER IN
          CONTRACT, TORT (INCLUDING STRICT LIABILITY OR NEGLIGENCE) OR
          OTHERWISE, SHALL BE $10. SOME JURISDICTIONS) DO NOT ALLOW THE
          EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
          ABOVE EXCLUSION OR LIMITATION MAY NOT APPLY TO YOU.
          <br />
          <br />
          2.3 Assumption of Risk. THE VALUE OF THE DAWN COLLECTION NFTS IS
          SUBJECTIVE, HAVE NO INHERENT VALUE AND THEREFOR CAN BE VOLATILE. YOU
          AGREE TO ASSUME ALL RISK ASSOCIATED WITH THE USE AND VALUE OF THE DAWN
          COLLECTION NFT AND NFT MEDIA.
          <br />
          <br />
          2.4 Fundamental Elements. THE EXCLUSIONS AND LIMITATIONS OF DAMAGES
          SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN
          BETWEEN THE CREATOR ENTITIES AND YOU.
          <br />
          <br />
          2.5 Indemnification. By entering into these Terms and accessing or
          using the Dawn Collection NFTs or NFT Media, you agree that you shall
          defend, indemnify and hold the Creator Entities harmless from and
          against any and all claims, costs, damages, losses, liabilities and
          expenses (including attorneys’ fees and costs) incurred by the Creator
          Entities arising out of or in connection with: (i) your violation or
          breach of any term of these Terms or any applicable law or regulation;
          (ii) your violation of any rights of any third party; (iii) your
          access to or use of the Dawn Collection NFT or NFT Media; (iv) any
          modifications to or derivative works of the NFT Media you create, or
          (v) any fraud, negligence or wilful misconduct committed by you. For
          these limited purposes, the Creator Entities (other than the Creator)
          are third party beneficiaries of the Terms.
          <br />
          <br />
          3. ADDITIONAL PROVISIONS
          <br />
          <br />
          3.1 Additional Features. Creator may choose to make additional
          features, access, content, items or other benefits available to owners
          of Dawn Collection NFTs (“Additional Features”). Creator has no duty
          or obligation to provide you with any Additional Features, and you
          should not expect any Additional Features when acquiring a Dawn
          Collection NFT. Additional Features may be subject to additional terms
          and conditions, which may be presented to you at the time they are
          made available.
          <br />
          <br />
          3.2 Termination of License. If you materially breach any of the
          provisions of these Terms, Creator may terminate all of the licenses
          granted to you under these Terms. Creator will use commercially
          reasonable efforts to provide you with notice of such termination,
          though for the avoidance of doubt your licenses shall terminate
          regardless of whether such notice is actually received. Upon the
          termination of your licenses, you shall cease all use of the rights
          granted in Article 1, including, without limitation, ceasing all
          marketing, distribution, or sale of goods, services and media that
          feature the NFT Media and shall cease all further use of the NFT Media
          (including any NFT Media Derivatives), and all sublicenses you have
          granted in the NFT Media shall automatically terminate. The following
          sections shall survive the termination of these Terms and shall
          continue in full force and effect subsequent to and notwithstanding
          any termination of these Terms by Creator or you: Sections 1.3, 1.4,
          1.6, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 3.3 and 3.4. Termination will not
          limit any of Creator’s other rights or remedies at law or in equity.
          <br />
          <br />
          3.3 Miscellaneous. These Terms constitutes the entire and exclusive
          understanding and agreement between Creator and you regarding the Dawn
          Collection NFT and NFT Media and supersedes and replaces any and all
          prior oral or written understandings or agreements between Creator and
          you regarding the Dawn Collection NFT and NFT Media. If any provision
          of these Terms shall be unlawful, void or for any reason
          unenforceable, then that provision shall be deemed severable from
          these Terms and shall not affect the validity and enforceability of
          any remaining provisions. These Terms and the licenses granted
          hereunder may be freely assigned by Creator. Any purported assignment
          in violation of these Terms will be null and void. No waiver by either
          party of any breach or default hereunder shall be deemed to be a
          waiver of any preceding or subsequent breach or default.
          <br />
          <br />
          3.4 Governing Law & Arbitration. You and Creator shall cooperate in
          good faith to resolve any dispute, controversy or claim arising out
          of, relating to or in connection with these Terms, including with
          respect to the formation, applicability, breach, termination, validity
          or enforceability thereof (a “Dispute”). If the parties are unable to
          resolve a Dispute within ninety (90) days of notice of such Dispute
          being received by all parties, such Dispute shall be finally settled
          by Binding Arbitration (as defined below). Any Dispute not resolved
          within ninety (90) days shall be referred to and finally resolved by
          arbitration administered by the American Arbitration Association
          (“AAA”) under its Consumer Arbitration Rules (the “AAA Rules”) then in
          effect, except as modified by these Terms. The AAA Rules are available
          at www.adr.org or by calling 1-800-778-7879. A party who wishes to
          start arbitration must submit a written Demand for Arbitration to AAA
          and give notice to the other party as specified in the AAA Rules. The
          AAA provides a form Demand for Arbitration at www.adr.org. If your
          claim is for U.S. $10,000 or less, You may choose whether the
          arbitration will be conducted solely on the basis of documents
          submitted to the arbitrator, through a telephonic or video-conference
          hearing, or by an in-person hearing as established by the AAA Rules.
          If Your claim exceeds U.S. $10,000, the right to a hearing will be
          determined by the AAA Rules. Any arbitration hearings will take place
          in New York, New York, or if you request, the county (or parish) where
          You live, unless both Parties agree to a different location. The
          parties agree that the arbitrator shall have exclusive authority to
          decide all issues relating to the interpretation, applicability,
          enforceability and scope of this arbitration agreement. Payment of all
          filing, administration and arbitrator fees will be governed by the AAA
          Rules. The language to be used in the arbitral proceedings shall be
          English. The arbitration award shall be final and binding on the
          parties (“Binding Arbitration”). The parties undertake to carry out
          any award without delay and waive their right to any form of recourse
          insofar as such waiver can validly be made. Judgment upon the award
          may be entered by any court having jurisdiction thereof or having
          jurisdiction over the relevant party or its assets. You and Creator
          will each pay their respective attorneys’ fees and expenses. These
          Terms and any action related thereto will be governed by the laws of
          the State of New York, without regard to its conflict of laws
          provisions. Any dispute arising out of or related to these Terms is
          personal to you and Creator and will not be brought as a class
          arbitration, class action or any other type of representative
          proceeding. You and Creator each agree that any claims may only be
          brought on an individual basis and not as a plaintiff or class member
          in any purported class or representative action or other proceeding in
          which a person attempts to resolve a dispute as a representative of
          another person or group of persons. Unless both you and Creator agree
          otherwise, the arbitrator may not consolidate or join more than one
          person’s or party’s claims, and may not otherwise preside over any
          form of a consolidated, representative, or class proceeding. If you
          are a California resident, in accordance with Cal. Civ. Code § 1789.3,
          you may report complaints to the Complaint Assistance Unit of the
          Division of Consumer Services of the California Department of Consumer
          Affairs by contacting them in writing at 1625 North Market Blvd.,
          Suite N 112 Sacramento, CA 95834, or by telephone at (800) 952-5210.
          <br />
          <br />
          <br id="world-collection-digital-collectible-terms" />
          <br />
          <br />
          <h1>FYAT LUX WORLD COLLECTION DIGITAL COLLECTIBLE TERMS</h1>
          <br />
          <br />
          <b>Last Updated: 28 December 2023</b>
          <br />
          <br />
          <br />
          By acquiring lawful ownership to a non-fungible token (“NFT”)
          associated with the Fyat Lux World Collection made available under
          this NFT License (“Fyat Lux World Collection NFT”) created by the
          owner or entity that has created this Fyat Lux World Collection NFT
          (“Creator” or “we” or “our”), you agree to these NFT License Terms and
          Conditions (the “Terms”). When you lawfully own a Fyat Lux World
          Collection NFT, you own all personal property rights to the NFT
          underlying the Fyat Lux World Collection NFT (e.g., the right to
          freely sell, transfer, or otherwise dispose of that Fyat Lux World
          Collection NFT). However, your rights to the associated artwork,
          images, video, content or other works of authorship linked to your
          specific Fyat Lux World Collection NFT which was developed by Creator
          (“NFT Media:) are only as outlined below.
          <br />
          <br />
          1. NFT LICENSE GRANT
          <br />
          <br />
          1.1 NFT Media License. For as long as you lawfully own a Fyat Lux
          World Collection NFT, Creator grants you a non-exclusive, perpetual,
          worldwide license under our copyrights to use, distribute, reproduce,
          display, and perform (but not modify) the specific NFT Media linked to
          your Fyat Lux World Collection NFT for personal, non-commercial uses,
          with the right to sublicense such rights solely to third parties to
          enable the right to display the NFT Media, with Creator being a third
          party beneficiary to all such sublicenses with the ability to enforce
          such agreements. This license includes the right to display NFT Media
          as a profile picture. All intellectual property rights in and to the
          NFT Media and any other intellectual property rights of Creator not
          expressly licensed herein are reserved by Creator.
          <br />
          <br />
          1.2 No Rights to Trademarks. Nothing in these Terms is meant to grant
          you any rights to any logos, trademarks, service marks, and trade
          dress associated with Creator or the Fyat Lux World Collection NFTs
          (“Project Trademarks”). Unless you have our prior written approval,
          you may not use any Project Trademarks for any use that would require
          a license from us, including to register any domain names or social
          media accounts using any Project Trademarks or to advertise or promote
          any other products or services.
          <br />
          <br />
          1.3 Transfer and Sublicensing. The licenses granted in these Terms are
          non-transferrable, except that if you lawfully transfer ownership of
          your Fyat Lux World Collection NFT, the license to the NFT Media in
          Section 1.1 to you shall terminate upon the effective date of such
          transfer, and such licenses will be assigned to the new owner of the
          Fyat Lux World Collection NFT associated with such NFT Media. As a
          condition to sales, transfers or similar transactions of the Fyat Lux
          World Collection NFTs, the transferee agrees upon the acquisition of
          the Fyat Lux World Collection NFT that (a) the transferee is not a
          Restricted Party and (b) the transferee accepts these Terms. Further,
          if you choose to sublicense any of your licensed rights set forth in
          Section 1.1 above, you are only permitted to do so if any such
          sublicensees agree (i) that they are not Restricted Parties and (ii)
          that if your licensed rights in Section 1.1 are transferred (such as
          because you sell your Fyat Lux World Collection NFT), then any such
          sublicenses you have granted in such licensed rights will
          automatically terminate. Because virtually all public blockchains are
          licensed under open source licenses, it is possible that the
          blockchain may fork, merge, or duplicate the original blockchain that
          initially recorded ownership of your Fyat Lux World Collection NFT. In
          such case, any rights granted under these Terms to owners of any Fyat
          Lux World Collection NFT will only be granted to the lawful owners of
          such Fyat Lux World Collection NFT whose ownership is recorded on the
          mainnet version of the blockchain that is generally recognized and
          predominantly supported in the blockchain industry as the legitimate
          successor of the original blockchain (as determined in our sole
          discretion).
          <br />
          <br />
          1.4 Third Party Content. The Creator hereby represents and warrants to
          you that all of the copyrights in the NFT Media are owned by the
          Creator, and does not contain (a) any artwork, images, video, content
          or other works of authorship, (b) logos, trademarks, service marks, or
          trade dress or rights of personality in which the relevant
          intellectual property rights are not owned by the Creator (“Third
          Party Content”), provided that the foregoing shall not apply if the
          Creator has obtained a license to such Third Party Content consistent
          with the licenses under this Agreement or the Creator has supplemented
          this NFT License with an additional license that governs your right to
          use such Third Party Content.
          <br />
          <br />
          1.5 Restrictions. Notwithstanding any of the above, you may not use
          the NFT Media in any way that constitutes unlawful, defamatory,
          harassing, abusive, fraudulent, racist, hateful, vulgar, cruel,
          illegal or obscene, or that promotes any such activity, as determined
          in Creator’s sole discretion, it being understood that Creator may
          designate another entity such as a decentralized autonomous
          organization (“DAO”) or committee of a DAO to make this determination
          in Creator’s place, in which case Creator will be bound by that other
          entity’s decision. If a Fyat Lux World Collection NFT is
          fractionalized into smaller ownership interests (which may be
          represented by other tokens), the rights licensed hereunder do not
          transfer to each of the owners of such fractionalized interests in the
          Fyat Lux World Collection NFT, but are only granted to those who own
          all fractionalized interests in a Fyat Lux World Collection NFT or as
          may otherwise be agreed by the owners of such fractionalized interests
          if each of such owners agree that (a) the owner is not a Restricted
          Party and (b) the owner accepts these Terms. In order to purchase the
          Fyat Lux World Collection NFT if you are an individual, you must be 18
          years of age or older if the age of lawfully capacity of forming
          binding contracts is older in the relevant jurisdiction. If you are an
          entity, the individual agreeing to the Terms must have the legal
          authority to bind the entity. If (a) you are an individual, you agree
          on your own behalf and (b) if you are an entity, you agree that
          neither the entity nor any of your owners or investors or any of their
          directors, officers, employees, agents or affiliates acting on your
          behalf: (i) is related in any way to, the governments of, or any
          persons within, any country or jurisdiction under a U.S. embargo
          enforced by the Office of Foreign Assets Control (“OFAC”), or any
          persons who are named on any list of sanctioned individuals or
          entities; (ii) is (or has ever been) prohibited from the transaction
          pursuant to U.S. anti-money laundering, anti-terrorist, economic
          sanctions and asset control laws; and (iii) is resident in a country
          or jurisdiction under a U.S. embargo enforced by OFAC (“Restricted
          Parties”).
          <br />
          <br />
          2. DISCLAIMERS, LIMITATIONS OF LIABILITY, AND INDEMNIFICATION
          <br />
          <br />
          2.1 Disclaimers. YOUR ACCESS TO AND USE OF THE FYAT LUX WORLD
          COLLECTION NFT AND NFT MEDIA IS AT YOUR OWN RISK. EXCEPT AS EXPRESSLY
          SET FORTH HEREIN, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE
          LAW, CREATOR, ITS PARENTS, AFFILIATES, PARTNERS, EMPLOYEES, OFFICERS,
          DIRECTORS, CONTRACTORS, AGENTS, LICENSORS AND EQUITYHOLDERS (THE
          “CREATOR ENTITIES”) DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER
          EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
          NON-INFRINGEMENT IN THE FYAT LUX WORLD COLLECTION NFTS AND NFT MEDIA.
          THE CREATOR ENTITIES MAKE NO WARRANTY OR REPRESENTATION AND DISCLAIM
          ALL RESPONSIBILITY AND LIABILITY FOR: (A) THE COMPLETENESS, ACCURACY,
          AVAILABILITY, TIMELINESS, ORIGINALITY, SECURITY OR RELIABILITY OF THE
          FYAT LUX WORLD COLLECTION NFTS AND NFT MEDIA; (B) THE OPERATION OR
          COMPATIBILITY WITH ANY OTHER APPLICATION OR ANY PARTICULAR SYSTEM,
          DEVICE, BLOCKCHAIN, DIGITAL WALLET, HARDWARE OR MARKETPLACE; AND (C)
          WHETHER THE FYAT LUX WORLD COLLECTION NFTS AND NFT MEDIA WILL MEET
          YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE OR
          ERROR-FREE BASIS; AND (D) THE DELETION OF, OR THE FAILURE TO STORE OR
          TRANSMIT THE FYAT LUX WORLD COLLECTION NFTS AND NFT MEDIA. THE FYAT
          LUX WORLD COLLECTION NFTS AND NFT MEDIA ARE INTENDED FOR CONSUMER
          ENJOYMENT, USE AND CONSUMPTION ONLY.
          <br />
          <br />
          2.2 Limitations of Liability. TO THE EXTENT NOT PROHIBITED BY LAW, YOU
          AGREE THAT IN NO EVENT WILL THE CREATOR ENTITIES BE LIABLE (A) FOR ANY
          INDIRECT, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
          DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
          GOODS OR SERVICES, LOSS OF USE, DATA OR PROFITS, BUSINESS INTERRUPTION
          OR ANY OTHER DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE
          OR INABILITY TO USE THE FYAT LUX WORLD COLLECTION NFT OR THE NFT
          MEDIA), HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, WHETHER
          UNDER THESE TERMS OR OTHERWISE ARISING IN ANY WAY IN CONNECTION WITH
          THE FYAT LUX WORLD COLLECTION NFTS AND NFT MEDIA OR THESE TERMS AND
          WHETHER IN CONTRACT, PRODUCT LIABILITY OR TORT (INCLUDING STRICT
          LIABILITY OR NEGLIGENCE) OR OTHERWISE, EVEN IF THE CREATOR ENTITIES
          HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, OR (B) FOR ANY
          OTHER CLAIM, DEMAND OR DAMAGES WHATSOEVER RESULTING FROM OR ARISING
          OUT OF OR IN CONNECTION WITH THESE TERMS OR THE DELIVERY, USE OR
          PERFORMANCE OF THE FYAT LUX WORLD COLLECTION NFTS AND NFT MEDIA. THE
          MAXIMUM AGGREGATE LIABILITY OF THE CREATOR ENTITIES FOR ALL DAMAGES
          AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING STRICT
          LIABILITY OR NEGLIGENCE) OR OTHERWISE, SHALL BE $10. SOME
          JURISDICTIONS) DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
          OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION OR LIMITATION MAY NOT
          APPLY TO YOU.
          <br />
          <br />
          2.3 Assumption of Risk. THE VALUE OF THE FYAT LUX WORLD COLLECTION
          NFTS IS SUBJECTIVE, HAVE NO INHERENT VALUE AND THEREFOR CAN BE
          VOLATILE. YOU AGREE TO ASSUME ALL RISK ASSOCIATED WITH THE USE AND
          VALUE OF THE FYAT LUX WORLD COLLECTION NFT AND NFT MEDIA.
          <br />
          <br />
          2.4 Fundamental Elements. THE EXCLUSIONS AND LIMITATIONS OF DAMAGES
          SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN
          BETWEEN THE CREATOR ENTITIES AND YOU.
          <br />
          <br />
          2.5 Indemnification. By entering into these Terms and accessing or
          using the Fyat Lux World Collection NFTs or NFT Media, you agree that
          you shall defend, indemnify and hold the Creator Entities harmless
          from and against any and all claims, costs, damages, losses,
          liabilities and expenses (including attorneys’ fees and costs)
          incurred by the Creator Entities arising out of or in connection with:
          (i) your violation or breach of any term of these Terms or any
          applicable law or regulation; (ii) your violation of any rights of any
          third party; (iii) your access to or use of the Fyat Lux World
          Collection NFT or NFT Media; or (iv) any fraud, negligence or wilful
          misconduct committed by you. For these limited purposes, the Creator
          Entities (other than the Creator) are third party beneficiaries of the
          Terms.
          <br />
          <br />
          3. ADDITIONAL PROVISIONS
          <br />
          <br />
          3.1 Additional Features. Creator may choose to make additional
          features, access, content, items or other benefits available to owners
          of Fyat Lux World Collection NFTs (“Additional Features”). Creator has
          no duty or obligation to provide you with any Additional Features, and
          you should not expect any Additional Features when acquiring a Fyat
          Lux World Collection NFT. Additional Features may be subject to
          additional terms and conditions, which may be presented to you at the
          time they are made available.
          <br />
          <br />
          3.2 Termination of License. If you materially breach any of the
          provisions of these Terms, Creator may terminate all of the licenses
          granted to you under these Terms. Creator will use commercially
          reasonable efforts to provide you with notice of such termination,
          though for the avoidance of doubt your licenses shall terminate
          regardless of whether such notice is actually received. Upon the
          termination of your licenses, you shall cease all use of the rights
          granted in Article 1 and shall cease all further use of the NFT Media,
          and all sublicenses you have granted in the NFT Media shall
          automatically terminate. The following sections shall survive the
          termination of these Terms and shall continue in full force and effect
          subsequent to and notwithstanding any termination of these Terms by
          Creator or you: Sections 1.3, 1.4, 1.6, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6,
          3.3 and 3.4. Termination will not limit any of Creator’s other rights
          or remedies at law or in equity.
          <br />
          <br />
          3.3 Miscellaneous. These Terms constitutes the entire and exclusive
          understanding and agreement between Creator and you regarding the Fyat
          Lux World Collection NFT and NFT Media and supersedes and replaces any
          and all prior oral or written understandings or agreements between
          Creator and you regarding the Fyat Lux World Collection NFT and NFT
          Media. If any provision of these Terms shall be unlawful, void or for
          any reason unenforceable, then that provision shall be deemed
          severable from these Terms and shall not affect the validity and
          enforceability of any remaining provisions. These Terms and the
          licenses granted hereunder may be freely assigned by Creator. Any
          purported assignment in violation of these Terms will be null and
          void. No waiver by either party of any breach or default hereunder
          shall be deemed to be a waiver of any preceding or subsequent breach
          or default.
          <br />
          <br />
          3.4 Governing Law & Arbitration. You and Creator shall cooperate in
          good faith to resolve any dispute, controversy or claim arising out
          of, relating to or in connection with these Terms, including with
          respect to the formation, applicability, breach, termination, validity
          or enforceability thereof (a “Dispute”). If the parties are unable to
          resolve a Dispute within ninety (90) days of notice of such Dispute
          being received by all parties, such Dispute shall be finally settled
          by Binding Arbitration (as defined below). Any Dispute not resolved
          within ninety (90) days shall be referred to and finally resolved by
          arbitration administered by the American Arbitration Association
          (“AAA”) under its Consumer Arbitration Rules (the “AAA Rules”) then in
          effect, except as modified by these Terms. The AAA Rules are available
          at www.adr.org or by calling 1-800-778-7879. A party who wishes to
          start arbitration must submit a written Demand for Arbitration to AAA
          and give notice to the other party as specified in the AAA Rules. The
          AAA provides a form Demand for Arbitration at www.adr.org. If your
          claim is for U.S. $10,000 or less, You may choose whether the
          arbitration will be conducted solely on the basis of documents
          submitted to the arbitrator, through a telephonic or video-conference
          hearing, or by an in-person hearing as established by the AAA Rules.
          If Your claim exceeds U.S. $10,000, the right to a hearing will be
          determined by the AAA Rules. Any arbitration hearings will take place
          in New York, New York, or if you request, the county (or parish) where
          You live, unless both Parties agree to a different location. The
          parties agree that the arbitrator shall have exclusive authority to
          decide all issues relating to the interpretation, applicability,
          enforceability and scope of this arbitration agreement. Payment of all
          filing, administration and arbitrator fees will be governed by the AAA
          Rules. The language to be used in the arbitral proceedings shall be
          English. The arbitration award shall be final and binding on the
          parties (“Binding Arbitration”). The parties undertake to carry out
          any award without delay and waive their right to any form of recourse
          insofar as such waiver can validly be made. Judgment upon the award
          may be entered by any court having jurisdiction thereof or having
          jurisdiction over the relevant party or its assets. You and Creator
          will each pay their respective attorneys’ fees and expenses. These
          Terms and any action related thereto will be governed by the laws of
          the State of New York, without regard to its conflict of laws
          provisions. Any dispute arising out of or related to these Terms is
          personal to you and Creator and will not be brought as a class
          arbitration, class action or any other type of representative
          proceeding. You and Creator each agree that any claims may only be
          brought on an individual basis and not as a plaintiff or class member
          in any purported class or representative action or other proceeding in
          which a person attempts to resolve a dispute as a representative of
          another person or group of persons. Unless both you and Creator agree
          otherwise, the arbitrator may not consolidate or join more than one
          person’s or party’s claims, and may not otherwise preside over any
          form of a consolidated, representative, or class proceeding. If you
          are a California resident, in accordance with Cal. Civ. Code § 1789.3,
          you may report complaints to the Complaint Assistance Unit of the
          Division of Consumer Services of the California Department of Consumer
          Affairs by contacting them in writing at 1625 North Market Blvd.,
          Suite N 112 Sacramento, CA 95834, or by telephone at (800) 952-5210.
        </SMainText>
      </STermsContainer>
    </SContainer>
  );
};

export default DigitalCollectibleTerms;
