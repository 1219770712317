import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

import ScrollView, { useProgress } from "./ScrollView";
import Header from "./Header";

import "./MainPage.scss";
import { easeInQuart, scale } from "../utils";

// Positions
export const announcement = { enter: 0.0, exit: 0.3 };
export const clip = { enter: 0.0, exit: 0.5 };
export const about = { enter: announcement.exit, exit: 3.0 };
// export const aboutTech = { enter: about.exit, exit: 5.5 };
export const vaultTeaser = { enter: about.exit, exit: 5.5 };

// Animation functions
const boatTranslate = (x: number) =>
  easeInQuart(1 - scale(x, clip.enter, about.exit - 0.2));

// const monolithTranslate = (x: number) =>
//   easeInQuart(1 - scale(x, aboutTech.enter, aboutTech.exit - 0.2));

const clipOpacity = (x: number) => scale(x, clip.enter, clip.exit);

const vaultTeaserBackgroundOpacity = (x: number) =>
  scale(x, vaultTeaser.enter, vaultTeaser.enter + 0.35);

const aboutBackgroundOpacity = (x: number) =>
  1 - scale(x, about.exit, about.exit);

const textOpacityRate = 0.26;

const fgOpacity = (x: number, enter: number, exit: number) =>
  scale(x, enter, enter + textOpacityRate) *
  (1 - scale(x, exit - textOpacityRate, exit));

const fgOpacityNoExit = (x: number, enter: number, exit: number) =>
  scale(x, enter, enter + textOpacityRate);

const headerLightnessRate = 4.5;

const headerLightness = (x: number) =>
  scale(x, 0, headerLightnessRate) * scale(x, 0, headerLightnessRate);

export default function MainPage(props: {
  entrance?: number;
  connectWallet: () => void;
  killSession: () => void;
  connected: boolean;
  address: string;
  logAnalytics: (eventName: string) => void;
}) {
  // const entrance = props.entrance

  const location = useLocation();

  useEffect(() => {
    console.log("main page");
    props.logAnalytics("main page");
  }, [location]);

  const progress = useProgress();

  // NOTE: This is used for linking to sections, but it also annoyingly returns you to the beginning on refresh.
  //
  // useEffect(() => {
  //     if (initialProgress !== undefined) {
  //         const el = document.documentElement
  //         el.scrollTo(0, el.clientHeight * initialProgress)
  //     }
  // }, [initialProgress])

  return (
    <div className="main-page">
      <Header
        connectWallet={props.connectWallet}
        killSession={props.killSession}
        connected={props.connected}
        address={props.address}
        lightness={headerLightness(progress)}
      />
      <ScrollView
        foreground={[
          {
            exit: announcement.exit,
            style: {
              opacity: fgOpacity(progress, -1, announcement.exit),
            },
          },
          {
            exit: about.exit,
            style: {
              opacity: fgOpacity(progress, announcement.exit + 1.0, about.exit),
            },
            children: <About />,
          },
          // {
          //   exit: aboutTech.exit,
          //   style: {
          //     opacity: fgOpacity(progress, about.exit + 1.0, aboutTech.exit),
          //   },
          //   children: <AboutTech />,
          // },
          {
            exit: vaultTeaser.exit + 1.0,
            style: {
              opacity: fgOpacityNoExit(
                progress,
                about.exit + 1.0,
                vaultTeaser.exit
              ),
            },
            children: <VaultTeaser />,
          },
          // {
          //     exit: vault.exit,
          //     style: {
          //         opacity: fgOpacity(progress, joinDiscord.exit + 0.2, vault.exit),
          //     },
          //     children: <Vault progress={scale(progress, joinDiscord.exit + 0.2 + textOpacityRate, vault.exit - textOpacityRate)} />
          // }, {
          //     exit: connect.exit,
          //     // style: { opacity: textOpacity(progress) },
          //     children: <Connect />
          // }, {
          //     exit: trailer.exit,
          //     // style: { opacity: textOpacity(progress) },
          //     children: <Trailer />
          // }, {
          //     exit: glimpses.exit,
          //     // style: { opacity: textOpacity(progress) },
          //     children: <Glimpses />
          // }
        ]}
        background={[
          // {
          //     enter: glimpses.enter,
          //     exit: glimpses.exit,
          //     style: {
          //         opacity: 1,
          //     }
          // }, {
          //     enter: vault.enter,
          //     exit: vault.exit,
          //     style: {
          //         transform: `translateY(${100 * 0}%)`
          //     }
          // },
          {
            enter: vaultTeaser.enter,
            exit: vaultTeaser.exit + 1.0,
            style: {
              opacity: 1.0 + vaultTeaserBackgroundOpacity(progress),
            },
          },
          // {
          //   enter: clip.enter,
          //   exit: aboutTech.exit,
          //   style: {
          //     transform: `translateY(${
          //       100 * monolithTranslate(progress) - 40
          //     }%)`,
          //   },
          // },
          // {
          //   enter: clip.enter,
          //   exit: aboutTech.exit,
          //   style: {
          //     transform: `translateY(${
          //       140 * monolithTranslate(progress) - 40
          //     }%)`,
          //   },
          // },
          {
            enter: clip.enter,
            exit: about.exit,
            style: {
              transform: `translateY(${100 * boatTranslate(progress) - 40}%)`,
              opacity: aboutBackgroundOpacity(progress),
            },
          },
          {
            enter: clip.enter,
            exit: about.exit,
            style: {
              transform: `translateY(${140 * boatTranslate(progress) - 40}%)`,
              opacity: aboutBackgroundOpacity(progress),
            },
          },
          {
            enter: clip.enter,
            exit: clip.exit,
            style: {
              opacity: clipOpacity(progress),
            },
          },
          {
            enter: clip.enter,
            exit: clip.exit,
          },
        ]}
      />
    </div>
  );
}

// function Announcement() {
//   return (
//     <div className="announcement-container">
//       <div className="top-fg">
//         <img
//           src="/fyatlux_logo_black_yellow.png"
//           className="announcement-logo"
//         />
//       </div>
//       <div className="text announcement latest">
//         <p>Vault Of The Ancients Phygitals are now available!</p>
//         <Link to="/vota-phygitals">
//           <button className="light">Go to Store</button>
//         <p>Experience all 6 episodes of Fyat Lux's 1st season now!</p>
//         <Link to="/vault-of-the-ancients">
//           <button className="light">Experience now!</button>
//         </Link>
//       </div>
//     </div>
//   );
// }

function About() {
  return (
    <div className="about-container">
      <div className="text description">
        <h1>AN EPIC 3D AVATAR COLLECTION FOR THE METAVERSE</h1>
        <h2 className="mobile-invisible">The Story</h2>
        <p className="mobile-invisible">
          Set thousands of years after an event called the Flux stopped all
          technology from working, rare people called Sparks are born with the
          ability to turn on ancient technology from humanity’s golden age. On
          one side are the Fyat, a powerful religion whose Spark enforcers
          restrict the use of ancient technology. On the other side are the Lux,
          a small group of Sparks who seek to unlock the secrets held by that
          same technology. Humanity’s future hangs in the balance.
        </p>
        <p className="mobile-visible">
          Set thousands of years after an event called the Flux stopped all
          technology, rare people called Sparks are born with the ability to
          turn on ancient tech from humanity’s golden age.
        </p>
        <h2 className="mobile-invisible">
          The Dawn Collection Digital Collectibles
        </h2>
        <p className="mobile-invisible">
          The Fyat Lux Dawn collection consists of 8,080 official 3D avatars
          associated with the Fyat Lux universe. Designed to be beautiful in 2D
          and 3D, each avatar is a metaverse-ready 3D masterpiece generated from
          200+ meticulously crafted traits.
        </p>
        <p className="mobile-visible">
          The Fyat Lux Dawn collection consists of 8,080 official 3D avatars
          associated with the Fyat Lux universe. Designed to be beautiful in 2D
          and 3D, each avatar is a metaverse-ready 3D masterpiece generated from
          200+ meticulously crafted traits.
        </p>
        <br />
      </div>
    </div>
  );
}

// function AboutTech() {
//   return (
//     <div className="about-tech-container">
//       <div className="text description">
//         <h1>Groundbreaking Technology & Experience</h1>
//         <h2 className="mobile-invisible">The Dawn Collection NFTs</h2>
//         <p className="mobile-invisible">
//           Through Web3 technologies like NFTs, we enable super-fans to own an
//           official part of the Fyat Lux world, unlock special privileges, and
//           earn unique rewards. Our first NFT collection - the Dawn series -
//           consists of 8,080 official Spark 3D avatars from the Fyat Lux
//           universe. Each avatar is a metaverse-ready 3D masterpiece generated
//           from 200+ meticulously crafted traits.
//         </p>
//         <p className="mobile-visible">
//           The Dawn collection consists of 8,080 Spark NFTs from the Fyat Lux
//           universe. Each NFT is a metaverse-ready 3D avatar masterpiece,
//           generated from 200 meticulously crafted traits.
//         </p>
//         <h2 className="mobile-invisible">The Phygital Experience</h2>
//         <p className="mobile-invisible">
//           Each Dawn Collection NFT owner can claim a groundbreaking “phygital”
//           smart card that is tethered to that unique NFT. These phygital cards
//           unlock the Fyat Lux mobile app, which delivers an expanding list of
//           phygital utility via augmented reality - from viewing an NFT in AR to
//           personifying an NFT via a face filter - with more experiences to come.
//         </p>
//         <p className="mobile-visible">
//           Each NFT owner can claim a groundbreaking “phygital” smart card that
//           is tethered to that NFT. The cards unlock the Fyat Lux mobile app,
//           which delivers a growing list of phygital utility through augmented
//           reality.
//         </p>
//         <br />
//         <a href="https://opensea.io/collection/fyat-lux-dawn">
//           <button>Available on OpenSea</button>
//         </a>
//       </div>
//     </div>
//   );
// }

function VaultTeaser() {
  return (
    <div className="vault-teaser">
      <div className="text description">
        <h1>THE FYAT LUX STORY</h1>
        <h2 className="mobile-invisible">The Vault of the Ancients</h2>
        <p className="mobile-invisible">
          The Vault of the Ancients is the prologue of Fyat Lux. Released in
          Summer 2021, the season is split into six parts that consist of
          high-quality illustrations, an immersive audio-drama, and the story
          text, all delivered in a custom story viewer for everyone to enjoy.
        </p>
        <p className="mobile-visible">
          The Vault of the Ancients is the first story cycle and the prologue of
          the main story of Fyat Lux. Follow Null and his trusty companion Ninez
          as they enter the Vault and uncover its secrets!
        </p>
        <Link to="/vault-of-the-ancients">
          <button className="light">Click Here to begin</button>
        </Link>
        <h2 className="mobile-invisible">Glimpses</h2>
        <p className="mobile-invisible">
          The world of Fyat Lux extends far beyond the characters and plot of
          the Vault of the Ancients. Experience even more Fyat Lux through nine
          illustrated Glimpses into the past, present, and future of this world.
        </p>
        <p className="mobile-visible">
          The world of Fyat Lux extends far beyond the characters and plot of
          the Vault of the Ancients. Experience even more Fyat Lux through nine
          illustrated Glimpses into the past, present, and future of this world.
        </p>
        <br />
        <Link to="/glimpses">
          <button className="light">Read Glimpses</button>
        </Link>
      </div>
      <div className="legal">
        <div className="copyrights">
          @2024 Fyat Lux. All Rights Reserved.
          <br />
        </div>
        <Link className="legal-link" to="/digital-collectible-terms">
          Digital Collectible Terms
        </Link>
        •
        <Link className="legal-link" to="/terms-of-service">
          Terms of Service
        </Link>
        •
        <Link className="legal-link" to="/privacy">
          Privacy Policy
        </Link>
        •
        <Link className="legal-link" to="/marketing-policy">
          Marketing Policy
        </Link>
      </div>
    </div>
  );
}
