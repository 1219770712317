import axios, { AxiosInstance } from 'axios'
import { IAssetData } from './types'

const api: AxiosInstance = axios.create({
  baseURL: 'https://ethereum-api.xyz',
  timeout: 30000, // 30 secs
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const chainToProvider = {
    4: 'http://localhost:5001/fyatluxtest/us-central1/api',
    1: 'https://us-central1-fyatluxtest.cloudfunctions.net/api'
}

export async function apiGetCheckoutChallenge(address: string, chainId:number): Promise<string> {
    return new Promise<string>(async(resolve, reject) => {
      try {
         const res = await axios.get(chainToProvider[chainId] + '/challenge/' + address)
        console.log(res)
         resolve(res.data.challenge)
      } catch (error) {
        reject("Error accessing api." + error.message)
      }
    })
}

export async function apiGetVotingPower(sparks: number[], chainId:number): Promise<any> {
    const bodyParameters = {'sparks': sparks, 'event': 'VOTA'};
    console.log("chain", chainId);

    return new Promise<any>(async(resolve, reject) => {
      try {
         const res = await axios.post(chainToProvider[chainId] + '/getVotingPower', bodyParameters)
         resolve(res.data);
      } catch (error) {
        reject("Voting power failed." + error.message)
      }
    })
}

export async function apiGetVotaCheckoutLink(items: number[], chainId:number): Promise<string> {
  const bodyParameters = {'items': items, 'event': 'VOTA'};
  console.log("chain", chainId);

  return new Promise<string>(async(resolve, reject) => {
    try {
       const res = await axios.post(chainToProvider[chainId] + '/checkoutWorldTest/0xe01c949d2F268331f85EBD374e13597920F2279A', bodyParameters)
       resolve(res.data);
    } catch (error) {
      reject("Checkout failed." + error.message)
    }
  })
}

export async function apiSubmitChallengeVote(address: string, signature: string, chainId:number, optionId: number): Promise<string> {
  const bodyParameters = {'challenge': signature, 'address': address, 'event': 'VOTA', 'optionId': optionId};

  return new Promise<string>(async(resolve, reject) => {
    try {
       const res = await axios.post(chainToProvider[chainId] + '/CastVote', bodyParameters)
       resolve(res.data)
    } catch (error) {
      reject("Challenge failed." + error.message)
    }
  })
}

export async function apiGetAccountAssets(
  address: string,
  chainId: number
): Promise<IAssetData[]> {
  const response = await api.get(
    `/account-assets?address=${address}&chainId=${chainId}`
  )
  const { result } = response.data
  return result
}

