import React from "react";
import styled from "styled-components";
import SparkSelector from "src/components/SparkSelector";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import "./MyCollection.scss";
import SparkDetails from "src/components/SparkDetails";
import TokenDetails from "src/components/TokenDetails";

const SSparkSelectionContainer = styled.div`
  height: 95vh;
  width: 100vw;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: flex-end;
  padding-top: 200px;
`;

interface ICollectionProps {
  selectSparkFunction: (tokenIndex: number, isWorldToken: boolean) => void;
  exitDetailsFunction: () => void;
  tokenMetadata: any;
  worldTokenMetadata: any[];
  numWorldTokens: number[];
  tokenIds: any;
  activeTokenIndex: number;
  activeTokenIsWorld: boolean;
  logAnalytics: (eventName: string) => void;
}

export default function MyCollectionPage(props: ICollectionProps) {
  const {
    selectSparkFunction,
    exitDetailsFunction,
    tokenMetadata,
    tokenIds,
    worldTokenMetadata,
    numWorldTokens,
    activeTokenIndex,
    activeTokenIsWorld,
  } = props;

  console.log(tokenMetadata);
  console.log(activeTokenIndex);
  const location = useLocation();

  useEffect(() => {
    console.log("my collection page");
    props.logAnalytics("my collection page");
  }, [location]);

  return (
    <div className="my-collection-page">
      {activeTokenIndex < 0 ? (
        <>
          <div className="my-collection-fg">
            <div className="title">
              <h1>My Collection</h1>
            </div>
          </div>
          <SSparkSelectionContainer>
            {tokenMetadata.map((d: any, i: number) => (
              <SparkSelector
                selectFunc={selectSparkFunction}
                tokenId={tokenIds[i]}
                index={i}
                key={`${d.name}`}
                metadata={d}
                numTokens={1}
                isWorldToken={false}
              />
            ))}
            {worldTokenMetadata.map((d: any, i: number) =>
              numWorldTokens[i] > 0 ? (
                <SparkSelector
                  selectFunc={selectSparkFunction}
                  tokenId={i + 1}
                  numTokens={numWorldTokens[i]}
                  index={i}
                  key={i}
                  metadata={d}
                  isWorldToken={true}
                />
              ) : (
                ""
              )
            )}
          </SSparkSelectionContainer>
        </>
      ) : activeTokenIsWorld ? (
        <TokenDetails
          tokenId={activeTokenIndex + 1}
          metadata={worldTokenMetadata[activeTokenIndex]}
          returnToSelectorFunc={exitDetailsFunction}
        />
      ) : (
        <SparkDetails
          tokenId={tokenIds[activeTokenIndex]}
          metadata={tokenMetadata[activeTokenIndex]}
          returnToSelectorFunc={exitDetailsFunction}
        />
      )}
    </div>
  );
}
