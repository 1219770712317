import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./VaultPage.scss";

export default function VaultPage(props: {
  progress?: number;
  logAnalytics: (eventName: string) => void;
}) {
  const [vaultBreakpoint, updateVaultBreakpoint] = useState(
    window.innerWidth <= 1500
  );

  window.addEventListener("resize", () => {
    updateVaultBreakpoint(window.innerWidth <= 1500);
  });

  const location = useLocation();

  useEffect(() => {
    props.logAnalytics("vault page");
  }, [location]);

  return (
    <div className="vault-page">
      <div className="vault-fg">
        <div className="vault-text-original">
          <h1>Vault of the Ancients</h1>
          <p>
            The Vault of the Ancients is the inaugural story cycle and the
            prologue of the main story of Fyat Lux. The chapter is split into
            six parts that will be released over a period of two months. Each
            part will consist of high-quality illustrations, an immersive
            audio-drama, and the story text, all delivered in an immersive way
            through our website to everyone.
          </p>
          <p>
            Released under{" "}
            <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/">
              Creative Commons BY-NC-SA
            </a>
          </p>
        </div>

        {vaultBreakpoint ? (
          <div className="vault-st">
            <div className="vault-st-list">
              <div className="vault-st-event">
                <div className="vault-st-image -interior1">
                  <h2 className="vault-st-name">Part 1</h2>
                  <a
                    href="https://fyat-player.web.app/?part=VOTA-part1"
                    onClick={() => {
                      props.logAnalytics("player column page");
                      props.logAnalytics("player part 1");
                    }}>
                    <button className="vault-st-btn">Experience Now</button>
                  </a>
                </div>
                <div className="vault-st-text">
                  The legendary relic hunter Null and his faithful synth
                  companion Ninez finally enter the mysterious vault they have
                  sacrificed so much to find. What mysteries lie in the infinite
                  corridor? And what waits at its end?
                </div>
              </div>

              <div className="vault-st-event">
                <div className="vault-st-image -interior2">
                  <h2 className="vault-st-name">Part 2</h2>
                  <a
                    href="https://fyat-player.web.app/?part=VOTA-part2"
                    onClick={() => {
                      props.logAnalytics("player column page");
                      props.logAnalytics("player part 2");
                    }}>
                    <button className="vault-st-btn">Experience Now</button>
                  </a>
                </div>
                <div className="vault-st-text">
                  Trapped in an ancient ruin, Ninez and Null must once again
                  rely on each other’s friendship and bravery to survive. Will
                  they make it? And at what cost?
                </div>
              </div>

              <div className="vault-st-event">
                <div className="vault-st-image -interior3">
                  <h2 className="vault-st-name">Part 3</h2>
                  <a
                    href="https://fyat-player.web.app/?part=VOTA-part3"
                    onClick={() => {
                      props.logAnalytics("player column page");
                      props.logAnalytics("player part 3");
                    }}>
                    <button className="vault-st-btn">Experience Now</button>
                  </a>
                </div>
                <div className="vault-st-text">
                  The duo take a break from their near-death experience and
                  reflect on their past, their mission, and the path that still
                  lies ahead.
                </div>
              </div>

              <div className="vault-st-event">
                <div className="vault-st-image -interior4">
                  <h2 className="vault-st-name">Part 4</h2>
                  <a
                    href="https://fyat-player.web.app/?part=VOTA-part4"
                    onClick={() => {
                      props.logAnalytics("player column page");
                      props.logAnalytics("player part 4");
                    }}>
                    <button className="vault-st-btn">Experience Now</button>
                  </a>
                </div>
                <div className="vault-st-text">
                  The main chamber awaits for the tattered adventurers. What
                  lies within? And what happens next? Only Dawn Collection NFT
                  holders can decide!
                </div>
              </div>

              <div className="vault-st-event -gold">
                <div className="vault-st-image -community -interior5" />
                <div className="vault-st-text">
                  <h2>Community Decision</h2>
                  <div className="vault-st-text">
                    The Community Decision is complete!
                  </div>
                </div>
              </div>

              <div className="vault-st-event">
                <div className="vault-st-image -interior6">
                  <h2 className="vault-st-name">Part 5</h2>
                  <a
                    href="https://fyat-player.web.app/?part=VOTA-part5"
                    onClick={() => {
                      props.logAnalytics("player column page");
                      props.logAnalytics("player part 5");
                    }}>
                    <button className="vault-st-btn">Experience Now</button>
                  </a>
                </div>
                <div className="vault-st-text">
                  Our heroes’ path has finally led them to the doorstep of a new
                  future. Their last step depends on one fateful decision. What
                  final sacrifice must be made?
                </div>
              </div>

              <div className="vault-st-event">
                <div className="vault-st-image -interior7">
                  <h2 className="vault-st-name">Part 6</h2>
                  <a
                    href="https://fyat-player.web.app/?part=VOTA-part6"
                    onClick={() => {
                      props.logAnalytics("player column page");
                      props.logAnalytics("player part 6");
                    }}>
                    <button className="vault-st-btn">Experience Now</button>
                  </a>
                </div>
                <div className="vault-st-text">
                  The decision has been made. The die has been cast. How will
                  Null and Ninez's journey end? The finale of Vault of the
                  Ancients is here!
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="vault-timeline">
            <div className="vault-event-image vault-row-1 -interior1" />
            <div className="vault-event-image vault-row-2 -interior2" />
            <div className="vault-event-image vault-row-3 -interior3" />
            <div className="vault-event-image vault-row-4 -interior4" />
            <div className="vault-event-image vault-row-6 -interior6" />
            <div className="vault-event-image vault-row-7 -interior7" />

            <div className="vault-event-dot vault-row-1" />
            <div className="vault-event-dot vault-row-2" />
            <div className="vault-event-dot vault-row-3" />
            <div className="vault-event-dot vault-row-4" />
            <div className="vault-event-dot vault-row-5" />
            <div className="vault-event-dot vault-row-6" />
            <div className="vault-event-dot vault-row-7" />

            <div className="dotted-line" />

            <div className="vault-brush-stroke vault-row-5" />

            <div className="vault-event-note vault-row-1">
              <h1>Part 1</h1>
              <p>
                The legendary relic hunter Null and his faithful synth companion
                Ninez finally enter the mysterious vault they have sacrificed so
                much to find. What mysteries lie in the infinite corridor? And
                what waits at its end?
              </p>
              <a
                href="https://fyat-player.web.app/?part=VOTA-part1"
                onClick={() => {
                  props.logAnalytics("player regular page");
                  props.logAnalytics("player part 1");
                }}>
                <button>Experience Now</button>
              </a>
            </div>
            <div className="vault-event-note vault-row-2">
              <h1>Part 2</h1>
              <p>
                Trapped in an ancient ruin, Ninez and Null must once again rely
                on each other’s friendship and bravery to survive. Will they
                make it? And at what cost?
              </p>
              <a
                href="https://fyat-player.web.app/?part=VOTA-part2"
                onClick={() => {
                  props.logAnalytics("player regular page");
                  props.logAnalytics("player part 2");
                }}>
                <button>Experience Now</button>
              </a>
            </div>
            <div className="vault-event-note vault-row-3">
              <h1>Part 3</h1>
              <p>
                The duo take a break from their near-death experience and
                reflect on their past, their mission, and the path that still
                lies ahead.
              </p>
              <a
                href="https://fyat-player.web.app/?part=VOTA-part3"
                onClick={() => {
                  props.logAnalytics("player regular page");
                  props.logAnalytics("player part 3");
                }}>
                <button>Experience Now</button>
              </a>
            </div>
            <div className="vault-event-note vault-row-4">
              <h1>Part 4</h1>
              <p>
                The main chamber awaits for the tattered adventurers. What lies
                within? And what happens next? Only Dawn Collection NFT holders
                can decide!
              </p>
              <a
                href="https://fyat-player.web.app/?part=VOTA-part4"
                onClick={() => {
                  props.logAnalytics("player regular page");
                  props.logAnalytics("player part 4");
                }}>
                <button>Experience Now</button>
              </a>
            </div>
            <div className="vault-event-note-nobg vault-row-5">
              <h1>Community Decision</h1>
              <p>The Community Decision is complete!</p>
            </div>
            <div className="vault-event-note vault-row-6">
              <h1>Part 5</h1>
              <p>
                Our heroes’ path has finally led them to the doorstep of a new
                future. Their last step depends on one fateful decision. What
                final sacrifice must be made?
              </p>
              <a
                href="https://fyat-player.web.app/?part=VOTA-part5"
                onClick={() => {
                  props.logAnalytics("player regular page");
                  props.logAnalytics("player part 5");
                }}>
                <button>Experience Now</button>
              </a>
            </div>
            <div className="vault-event-note vault-row-7">
              <h1>Part 6</h1>
              <p>
                The decision has been made. The die has been cast. How will Null
                and Ninez's journey end? The finale of Vault of the Ancients is
                here!
              </p>
              <a
                href="https://fyat-player.web.app/?part=VOTA-part6"
                onClick={() => {
                  props.logAnalytics("player regular page");
                  props.logAnalytics("player part 6");
                }}>
                <button>Experience Now</button>
              </a>
            </div>
          </div>
        )}
      </div>
      <div className="vault-bg1" />
      <div className="vault-bg2" />
      <div className="vault-bg3" />
    </div>
  );
}
