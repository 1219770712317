import * as React from "react";
import styled from "styled-components";

import unrevealed_token from "../assets/network_error.png";

const SSparkContainer = styled.div`
  height: 55vh;
  //min-height: 200px;
  //max-height: 400px;
  display: inline-flex;
  //flex-direction: column;
  //background-color: rgba(255, 255, 255, 1.0);
  justify-content: center;
  align-items: center;
  padding: 6px;
  margin: 35px;
  cursor: pointer;
  z-index: 100;

  transform: scale(1);
  transition-duration: 0.3s;

  &:hover {
    transform: scale(1.2);
    transition-duration: 0.3s;
    z-index: 102;
  }
  position: relative;
`;

const SSparkImage = styled.img`
  height: auto;
  max-height: 80%;
  box-shadow: rgba(0, 0, 0, 1) 0px 6px 12px;
  border-radius: 4px;
  flex: 1;
`;

const SBadgeIcon = styled.div`
  position: absolute;
  position: absolute;
  top: 5vh;
  right: -1vh;
  z-index: 1;
  width: 32px !important;
  height: 32px !important;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #000;
  text-align: center;
`;

const linkReplace = (text: string) => {
  console.log("replacing: " + text);
  let result = text.replace(
    "https://matiria.mypinata.cloud/ipfs/QmWh1xduETPQvESP5zZGZMpAFPrQy9grH1BATx8pDYwEFy",
    unrevealed_token
  );
  console.log("unrevealed: " + result);

  result = result.replace("ipfs://", "https://matiria.mypinata.cloud/ipfs/");
  console.log("result:" + result);
  return result;
};

interface ISparkSelectorProps {
  selectFunc: (tokenIndex: number, isWorldToken: boolean) => void;
  metadata: any;
  index: number;
  tokenId: number;
  numTokens: number;
  isWorldToken: boolean;
}

const SparkSelector = (props: ISparkSelectorProps) => {
  const { selectFunc, metadata, numTokens, index, isWorldToken } = props;

  return (
    <SSparkContainer onClick={() => selectFunc(index, isWorldToken)}>
      <SSparkImage
        src={linkReplace(metadata.image)}
        alt={"image of " + metadata.name}
      />
      {numTokens > 1 ? <SBadgeIcon color="grey">{numTokens}</SBadgeIcon> : ""}
    </SSparkContainer>
  );
};

export default SparkSelector;
