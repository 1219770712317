export function easeInQuart(x: number) {
    return x * x * x * x
}

export function easeOutQuart(x: number) {
    return 1 - Math.pow(1 - x, 4)
}


// Min-max rescaling
export function scale(x: number, min: number, max: number): number {
    return Math.max(0, Math.min(1, (x - min) / (max - min)));
}

// Surprised these aren't in the standard library or something like underscore.js.
export function filterMap<A, B>(arr: A[], f: (x: A, i: number) => (B | null)): B[] {
    const r = [];
    for (let i = 0; i < arr.length; i++) {
        const x = f(arr[i], i);
        if (x !== null) {
            r.push(x as B)
        }
    }
    return r;
}

export function findMap<A, B>(arr: A[], f: (x: A, i: number) => (B | null)): B | null {
    for (let i = 0; i < arr.length; i++) {
        const x = f(arr[i], i);
        if (x !== null) {
            return x as B;
        }
    }
    return null;
}

