import { FLUX_CONTRACT_TEST, FLUX_WORLD_CONTRACT } from '../constants/contracts'


export function getCollectibleContract(chainId: number, web3: any) {

  const fluxAutomata = new web3.eth.Contract(
    FLUX_CONTRACT_TEST[chainId as keyof typeof FLUX_CONTRACT_TEST].abi,
    FLUX_CONTRACT_TEST[chainId as keyof typeof FLUX_CONTRACT_TEST].address
  )

  return fluxAutomata
}

export function getFluxWorldContract(chainId: number, web3: any) {

  const fluxAutomata = new web3.eth.Contract(
    FLUX_WORLD_CONTRACT[chainId as keyof typeof FLUX_CONTRACT_TEST].abi,
    FLUX_WORLD_CONTRACT[chainId as keyof typeof FLUX_CONTRACT_TEST].address
  )

  return fluxAutomata
}

