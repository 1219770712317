import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import "./VotePage.scss";

interface IVoteFinishedProps {
  disconnectWallet: () => void;
  logAnalytics: (eventName: string) => void;
}

export default function VoteFinishedPage(props: IVoteFinishedProps) {
  const { disconnectWallet } = props;

  const location = useLocation();

  useEffect(() => {
    props.logAnalytics("vote finished page");
    disconnectWallet();
  }, [location]);

  return (
    <div className={"vote-page"}>
      <div className="top-section">
        <div className="vote-section">
          <div className="info-section">
            <div className="vote-title">
              <h1>The decision is yours...</h1>
            </div>
            <div className="vote-text">
              <p>
                The first community decision for the Fyat Lux universe is
                underway! From July 21, 2022 to August 2, 2022, any owner of a
                <a href="https://opensea.io/collection/fyat-lux-dawn">
                  {" Fyat Lux NFT "}
                </a>
                can choose between two options for the finale of our first
                chapter -{" "}
                <Link to="/vault-of-the-ancients">Vault of the Ancients</Link>.
              </p>
              <p>
                When we last saw our main characters - the legendary Null and
                his Synth companion Ninez - they were at a critical moment in
                their quest to unlock a new future for all Sparks. With Ninez
                badly damaged and a deadly Sentry standing in Null’s way to save
                Ninez and activate the Core, only you can decide what happens
                next.
              </p>
              <p>
                Your influence in the decision is determined by the total{" "}
                <a href="https://fyatlux.medium.com/flux-power-web3-community-agency-5cec332a5599">
                  {'"Flux Power"'}
                </a>{" "}
                of your NFTs. Your Flux Power will be displayed once you connect
                your wallet. The option that receives the most Flux Power from
                the community will become the finale to Vault of the Ancients!
              </p>
            </div>

            {<VotingPower votingPower={0} />}
          </div>
          {<AlreadyVoted />}
        </div>
      </div>
    </div>
  );
}

function VotingPower(props: { votingPower: any }) {
  return (
    <div className="voting-power">
      <br />
      <h1>Flux Power: Depleted</h1>
    </div>
  );
}

function AlreadyVoted() {
  const emailstyle = {
    display: "block",
    margin: "10px",
    maxWidth: "700px",
  };

  return (
    <div className="voted-section">
      <div className="vote-option">
        <div className="option-info-section-connected">
          <h2>Congrats! </h2>
          <p>
            You have successfully contributed your Flux to power your choice!
            Check back again in a few weeks to read the final part of Vault of
            the Ancients and discover which option received the most Flux Power.
            And keep an eye on your wallets - you may have earned yourself a
            piece of the Fyat Lux universe!
          </p>
        </div>
      </div>
      <iframe
        width="100%"
        height="600px"
        src="https://db99a2bb.sibforms.com/serve/MUIEAJNagBnGo8lVhaQaHqD8R46iRsT368xvPW0t-DdizggUTxou6Eq0YktddFw6j7EH-LsskrFNXWSuHjDJaptZlOQPywcZrDkDei6C6QEvIQad1tvkUpZ0v-srQTk6DR-JbQPpoxbhh3NIk8OOWp5eWZ8dvoXvR1pgvm_gGLmJXMK19JHJvQyhVwxnSAhtsnCF3mMQ0uMRQsC3"
        frameBorder="0"
        scrolling="auto"
        allowFullScreen
        style={emailstyle}
      />
    </div>
  );
}
